.heritage-nav{
	border-top:1px solid $color-grey-1;

	span{
		display: block;
	}
	.grid{
		li{
			&:last-child{
				width: auto;
				max-width: 50%;
			}
		}
	}
	a{
		display: block;
	}

	@include mobile() {
		.grid{
			li{
				&:first-child{
					&.pad-1 {
						padding: 75px 0 35px 0;
					}
				}
				&:last-child{
					width: auto;
					float: none;

					&.pad-1 {
						padding: 35px 0 75px 0;
					}
				}
			}
		}
	}
}
