@font-face {
	font-family: 'Avenir-Black';
	src: url('../medias/fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'),
		 url('../medias/fonts/Avenir-Black.woff2') format('woff2'),
		 url('../medias/fonts/Avenir-Black.woff') format('woff'),
		 url('../medias/fonts/Avenir-Black.ttf') format('truetype'),
		 url('../medias/fonts/Avenir-Black.svg#Avenir-Black') format('svg')
}
$Avenir-Black: 'Avenir-Black';

@font-face {
	font-family: 'Avenir-Book';
	src: url('../medias/fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'),
		 url('../medias/fonts/Avenir-Book.woff2') format('woff2'),
		 url('../medias/fonts/Avenir-Book.woff') format('woff'),
		 url('../medias/fonts/Avenir-Book.ttf') format('truetype'),
		 url('../medias/fonts/Avenir-Book.svg#Avenir-Book') format('svg')
}
$Avenir-Book: 'Avenir-Book';

@font-face {
	font-family: 'Avenir-Heavy';
	src: url('../medias/fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
		 url('../medias/fonts/Avenir-Heavy.woff2') format('woff2'),
		 url('../medias/fonts/Avenir-Heavy.woff') format('woff'),
		 url('../medias/fonts/Avenir-Heavy.ttf') format('truetype'),
		 url('../medias/fonts/Avenir-Heavy.svg#Avenir-Heavy') format('svg')
}
$Avenir-Heavy: 'Avenir-Heavy';

@font-face {
	font-family: 'Avenir-Light';
	src: url('../medias/fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'),
		 url('../medias/fonts/Avenir-Light.woff2') format('woff2'),
		 url('../medias/fonts/Avenir-Light.woff') format('woff'),
		 url('../medias/fonts/Avenir-Light.ttf') format('truetype'),
		 url('../medias/fonts/Avenir-Light.svg#Avenir-Light') format('svg')
}
$Avenir-Light: 'Avenir-Light';

@font-face {
	font-family: 'Avenir-Medium';
	src: url('../medias/fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
		 url('../medias/fonts/Avenir-Medium.woff2') format('woff2'),
		 url('../medias/fonts/Avenir-Medium.woff') format('woff'),
		 url('../medias/fonts/Avenir-Medium.ttf') format('truetype'),
		 url('../medias/fonts/Avenir-Medium.svg#Avenir-Medium') format('svg')
}
$Avenir-Medium: 'Avenir-Medium';

@font-face {
	font-family: 'Avenir-Roman';
	src: url('../medias/fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
		 url('../medias/fonts/Avenir-Roman.woff2') format('woff2'),
		 url('../medias/fonts/Avenir-Roman.woff') format('woff'),
		 url('../medias/fonts/Avenir-Roman.ttf') format('truetype'),
		 url('../medias/fonts/Avenir-Roman.svg#Avenir-Roman') format('svg')
}
$Avenir-Roman: 'Avenir-Roman';

