.about-expertises{
	border-bottom: 1px solid $color-grey-1;

	.txt-med-3{
		font-family: $Avenir-Medium;
	}

	@include mobile {
		.grid.pad-1,.grid.pad-1-bot{
			padding-bottom:50px;
		}
		.txt-med-3{
			font-size:$font-size-100 / 4;
		}
	}
}
