.close-burger{
	display: none;
}

html{
	&.overlay-scrollbar{
		&.mac{
			&.chrome{
				::-webkit-scrollbar {
					display:none;
				}
			}
		}
		// &.firefox{
		// 	overflow:hidden;
		// 	height:100%;

		// 	body{
		// 		overflow:hidden;
		// 		height:100%;
		// 	}
		// }
		// &.ie{
		// 	-ms-overflow-style: none;
		// }

		&.win{
			overflow:hidden;
			height:100%;

			body{
				overflow:hidden;
				height:100%;
			}

			&.chrome{
				::-webkit-scrollbar {
					visibility:hidden;
				}
			}


			// &.chrome{
			// 	// overflow:hidden;
			// 	height:100%;

			// 	body{
			// 		overflow:hidden;
			// 		height:100%;
			// 	}

			// 	.overlay::-webkit-scrollbar {
			// 		display:none;
			// 	}
			// }
		}
	}
}

header{

	position: relative;
	position: absolute;
	top: 0;
	width: 100%;
	height: 140px;
	z-index: 10;

	opacity:0;
	pointer-events: all;
	// visibility:hidden;

	// &.transition{
	// 	transition:opacity 800ms $ease-OutExpo 0ms;
	// }

	// &.show{
	// 	opacity:1;
	// 	visibility:visible;
	// }

	&.bg-dark{
		background-color: inherit;
		svg{
			fill: $color-white;
		}
		.main-nav{
			li{
				&.is-active{
					a, i{
						color: $color-white;
					}
				}
			}
		}
	}

	.top-nav{
		background-color:$color-black;
		width: 100%;
		height: 26px;
		margin-bottom: 115px;
		z-index: 10;
		li{
			float: right;
			a{
				color: $color-white;
				padding: 10px 8px 6px 8px;
				display: block;
			}
		}
	}

	.burger{
		display: none;
	}

	.logo{
		display: block;
		width: 112px;
		height: 42px;
		position: absolute;
		left: 54px;
		top: calc(50% + 12px);
		transform: translate3d(0, -50%, 0);
		z-index: 10;
	}

	.main-nav{
		position: absolute;
		top: calc(50% + 12px);
		transform: translate3d(0, -50%, 0);
		right: 40px;
		z-index: 10;

		li{
			float: left;
			&.is-active{
				a, i{
					color: $color-black;
				}
			}
			a, i{
				padding: 15px;
				cursor: pointer;
				color: $color-grey-3;
				transition: all 1600ms $ease-OutExpo 0ms;
			}
			&:last-child{
				display:none;
			}
		}

	}

	&.sticky{
		transform: translate3d(0, 0, 0) !important;
		transition: transform 800ms cubic-bezier(.19,1,.22,1) 0ms;
		position: fixed;
		height: 70px;
		top: -70px;
		background-color:$color-white;
		border-bottom: 1px solid #efefef;

		&.bg-dark{
			svg {
				fill: $color-black;
			}
			.main-nav li.is-active a,
			.main-nav li.is-active i {
				color: $color-black;
			}
		}

		.top-nav{
			display:none;
		}

		&.shown{
			transform: translate3d(0, 100%, 0) !important;
		}

		.logo{
			width: 100px;
			height: 38px;
			top: calc(50% - 0px);
		}
		.main-nav {
			top: calc(50% + 3px);
		}
	}

}

.overlay{
	position: fixed;
	overflow-y: auto;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0,0,0,1);
	padding: 60px;

	visibility:hidden;
	opacity: 0;
	pointer-events:none;
	z-index:100;
	// z-index:-100;

	transition:all 1600ms $ease-OutExpo 0ms;

	&.shown{
		transition:all 800ms $ease-OutExpo 0ms;
		visibility:visible;
		opacity:1;
		pointer-events:all !important;
		// z-index: 100;
	}

	.prev{
		display: none;
	}
	ul.nav{
		position: relative;
		li{
			position: relative;
			display: inline-block;

			.img{
				position: fixed;
				z-index: 1;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				pointer-events:none;
				transform: scale(1.2);
				transition: all 1600ms $ease-OutExpo 0ms;
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: auto;
					height: auto;
					min-width: 100%;
					min-height: 100%;
				}
			}
			span{
				position: absolute;
				margin-top: -10px;
				z-index: 10;
			}
			a{
				position:relative;
				display: inline;
				z-index: 10;
				padding-top:5px;
				&:after{
					content:'.';
					padding: 0 30px;
				}

				&.talent-disable{
					opacity:0.1 !important;
					pointer-events:none;
				}
			}
		}
	}
	&.js-overlay-projects{
		ul.nav{
			li{
				b{
					display:inline-block;
					visibility:visible;
				}
				&.active{
					.img{
						opacity: 0.2 !important;
						transform: scale(1.1) !important;
					}
				}
			}
		}
	}
	&:not(.js-overlay-projects){
		ul.nav{
			li{
				visibility:visible;
			}
		}
	}
	.close-overlay{
		pointer-events:none;
		opacity:0;

		transition:opacity 800ms $ease-OutExpo 0ms;

		&.visible{
			opacity:1;
			pointer-events:all;
		}
	}
}

html.ie{
	.overlay{
		z-index:-100;

		&.shown{
			z-index: 100;
		}
	}
}

@include tablet {

	.overlay{
		ul{
			li{
				.img{
					display: none;
				}
			}
		}
	}

}



@include mobile {

	.close-burger{
		&.shown{
			opacity: 1;
			pointer-events:all;
		}
		position: fixed;
		display: block;
		opacity: 0;
		pointer-events:none;
		transition: all 800ms $ease-OutExpo 0ms;
		z-index: 1000;
	}

	.overlay{
		padding: 100px 25px;
		ul.nav{
			li{
				&.categ{
					margin-top:50px;

					&:first-child{
						margin-top:0;
					}
				}
				display: block;
				span{
					position: inherit;
					display: block;
					margin: 20px 0;
				}
				.img{
					display: none;
				}
				a{
					&:after{
						display: none;
					}
				}
			}
		}
		.prev{
			display: block;
			position: fixed;

			opacity:0;
			pointer-events:none;

			transition: opacity 800ms $ease-OutExpo 0ms;

			&.visible{
				opacity:1;
				pointer-events:all;
			}
		}
		.close{
			display: none;
		}
	}

	header{

		svg{
			fill: $color-black !important;
		}

		opacity: 1;
		top: 0;
		pointer-events:none;
		background-color: inherit;

		&.bg-dark{
			background-color: inherit;
			svg{
				fill:$color-black;
			}
		}

		&:before{
			display: block;
			position: fixed;
			width: 100%;
			height: 80px;
			content:'';
			left: 0;
			top: 0;
			background-color: $color-white;
		}

		&:after{
			position: absolute;
			display: block;
			content:'';
			background-color: $color-black;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			opacity: 0;
			pointer-events:none;
			transition: all 800ms $ease-OutExpo 0ms;
		}

		&.collapsed{
			&:after{
				opacity: 1;
			}
			.main-nav, .top-nav{
				opacity: 1;
				pointer-events:all;
			}
			.logo{
				pointer-events:none;
				opacity: 0;
			}
		}

		*{
			color: $color-white !important;
		}

		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 100;
		transition: background 800ms $ease-OutExpo 0ms;

		.top-nav{
			position: absolute;
			bottom:0;
			margin: 0;
			background-color: inherit;
			opacity: 0;
			pointer-events:none;
			transition: all 800ms $ease-OutExpo 0ms;
			li{
				float: left;
				display: block;
				a{
					padding: 0 15px 0 25px;
				}
			}
		}

		.burger{
			pointer-events:all;
			display: block;
			position: absolute;
			height: 75px;
			width: 66px;
			right: 0;
			top: 0;
			transition: all 800ms $ease-OutExpo 0ms;
			i{
				width: 18px;
				height: 2px;
				background-color: $color-black;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -9px;
				margin-top: -1px;
				&:first-child{
					top: calc(50% - 5px);
				}
				&:last-child{
					top: calc(50% + 5px);
				}
			}
		}

		.logo{
			left: 25px;
			transform: translate3d(0, 0, 0);
			top: 20px;
			transition: all 800ms $ease-OutExpo 0ms;
			pointer-events: all;
		}

		.main-nav{
			left: 25px;
			right: inherit;
			z-index: inherit;
			opacity: 0;
			pointer-events:none;
			transition: all 800ms $ease-OutExpo 0ms;
			li{
				float: inherit;
				a, i{
					display: block;
					padding: 15px 0;
					font-size: $font-size-24;
				}
			}

		}

	}

}
