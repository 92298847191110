// -------------------------o Medias

// image and lazy load

.img{
	background-color: $color-grey-0;
	overflow: hidden;
	position: relative;
	img{
		width: 100%;
		opacity: 0;
		visibility:visible;
		z-index: 0;
		position: relative;
		transform: scale(1.2);
		transition: opacity 1800ms $ease-OutExpo 0ms, transform 1800ms $ease-OutExpo 0ms;

		&:not([data-lazy-scroll]){
			transform: scale(1);
			opacity: 1;
		}
	}
}

html.ie{
	.img{
		img{
			transform: scale(1);
			opacity:1;
		}
	}
}


html.iOS{
	.video{
		&.visible{
			img{
				&:not([data-lazy]):not([data-lazy-scroll]){
					opacity: 1 !important;
				}
			}
		}
	}
}


.video{
	&.visible{
		img{
			&:not([data-lazy]):not([data-lazy-scroll]){
				opacity: 0;
			}
		}
	}
	&.play-widget{
		iframe{
			opacity: 1;
			transform: scale(1);
		}
		.play{
			opacity: 0;
			transform: scale(0.6);
			pointer-events: none;
		}
	}
	video{
		position: absolute;
		width: 100%;
		top: 0;
		z-index: 0;
	}
	iframe{
		opacity: 0;
		transform: scale(1.1);
		transition: opacity 1800ms $ease-OutExpo 0ms, transform 1800ms $ease-OutExpo 0ms;
	}
	.play{
		transition: opacity 1800ms $ease-OutExpo 0ms, transform 1800ms $ease-OutExpo 0ms;
	}
}

svg{
	display: block;
	width: 100%;
	height: 100%;
}


.img iframe{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
