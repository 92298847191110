#cookies{
	position:fixed;
	bottom:0;left:0;
	width:100%;
	padding:30px;
	background-color:$color-black;
	z-index:100;
	pointer-events:all;

	&.hide{
		display:none;
	}

	> div{
		p{
			text-transform:none;
			font-size:0.929em;
			line-height:1.8em;
		}
		.close {
			top:50%;
			transform:translate(0,-50%);
		}
	}

	@include mobile{
		padding:20px 88px 20px 20px;

		> div{
			p{
				font-size:0.75em;
				line-height:1.5em;
			}
		}
	}
}
