// -------------------------o Clearfix

@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

// -------------------------o Font smoothing

@mixin font-smoothing(){
	body{
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
	}
}

// -------------------------o Font selection

@mixin font-selection-color($bg, $txt){

	*{
		::selection {
			background: $bg;
			color: $txt;
		}
		::-moz-selection {
			background: $bg;
			color: $txt;
		}
	}

}

// -------------------------o Box Sizing

@mixin box-sizing(){
	*, *:after, *:before {
		box-sizing: border-box;
	}
}

// -------------------------o Hack image (3D accelleration)

@mixin hack-images(){
	img {
		backface-visibility:hidden;
		transform: translate3d(0, 0, 0);
	}
}

// -------------------------o Set mobile tel color link

@mixin phone-link($color){
	a[href^=tel]:link { color: $color; }
}

// -------------------------o No selection

@mixin no-text-selection(){

	*:not(input):not(textarea) {
		touch-callout: none;
		user-select: none;
	}

}

@mixin no-img-selection(){

	img {
		pointer-events:none;
	}

}
