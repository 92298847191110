.home-scrolling{

	overflow: hidden;

	ul{

		width: 9999px;

		li{

			&:before{
				display: block;
				content: '';
				position: absolute;
				width: 10px;
				height: 10px;
				background-color: #000;
				border-radius: 100%;
				top: 50%;
				margin-top: -5px;
				left: 0;
			}

			position: relative;
			padding: 0 0 0 50px;
			margin: 0 50px 0 0;
			display: inline;

			&:last-child{
				margin: 0;
			}

		}
	}


	@include mobile {

		ul li{
			&:before{
				width: 6px;
				height: 6px;
				margin-top: -3px;
			}
			padding: 0 0 0 30px;
			margin: 0 30px 0 0;
		}
	}

}
