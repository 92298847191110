.heritage-intro{
	@include mobile {
		h2{
			padding-bottom:0;
		}
		p{
			display:none;
		}
	}
}
