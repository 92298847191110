.expertise-talents{
	// margin: 180px 0;
	// h2{
	// 	margin-bottom: 130px;
	// }
	.thumbs{

		@include clearfix();

		li{
			&.hidden{
				opacity: 0.5;
			}
			span{
				display: block;
				width: 100%;
				margin: 30px 0;
				text-align: center;
			}
			.img{
				display: block;
			}
			.img img{
				width: 100%;
			}
			transition: all 800ms $ease-OutExpo 0ms;
		}
	}
	.filters{
		li{
			&.is-active{
				color:$color-black;
			}
			transition: all 800ms $ease-OutExpo 0ms;
			line-height: 2.5em;
			color: $color-grey-2;
			cursor: pointer;
		}
	}

	@include tablet {
		.thumbs{
			li.col-1-3{
				width:33%;
			}
		}
	}
	@include mobile {
		// .grid.pad-1{
		// 	padding-bottom:30px;
		// }
		// .col-1-3:first-child{
		// 	display:none;
		// }
		// .thumbs{
		// 	li.col-1-3{
		// 		width:50%;
		// 		float:left;
		// 		margin:0;
		// 	}
		// }
		display:none;
	}
}
