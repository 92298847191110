.newsletter{
	border-top: 1px solid $color-grey-1;
	background:url( '../medias/images/others/back-news.jpg' ) no-repeat top left;
	background-size:cover;

	object{
		height:135px;
	}

	@include mobile{
		position:relative;

		&:before{
			content:"";
			position:absolute;
			height:100%;width:100%;
			top:0;left:0;
			z-index:0;
			background:rgba(0,0,0,.4);
		}

		object{
			position:relative;
			z-index:1;
			height:152px;
		}
	}
}
