/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$logo-ac-name: 'logo-ac';
$logo-ac-x: 421px;
$logo-ac-y: 0px;
$logo-ac-offset-x: -421px;
$logo-ac-offset-y: 0px;
$logo-ac-width: 66px;
$logo-ac-height: 66px;
$logo-ac-total-width: 487px;
$logo-ac-total-height: 425px;
$logo-ac-image: '../medias/images/css/sprite.png';
$logo-ac: (421px, 0px, -421px, 0px, 66px, 66px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-ac', );
$logo-aso-name: 'logo-aso';
$logo-aso-x: 275px;
$logo-aso-y: 122px;
$logo-aso-offset-x: -275px;
$logo-aso-offset-y: -122px;
$logo-aso-width: 138px;
$logo-aso-height: 44px;
$logo-aso-total-width: 487px;
$logo-aso-total-height: 425px;
$logo-aso-image: '../medias/images/css/sprite.png';
$logo-aso: (275px, 122px, -275px, -122px, 138px, 44px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-aso', );
$logo-canalplus-name: 'logo-canalplus';
$logo-canalplus-x: 0px;
$logo-canalplus-y: 393px;
$logo-canalplus-offset-x: 0px;
$logo-canalplus-offset-y: -393px;
$logo-canalplus-width: 124px;
$logo-canalplus-height: 32px;
$logo-canalplus-total-width: 487px;
$logo-canalplus-total-height: 425px;
$logo-canalplus-image: '../medias/images/css/sprite.png';
$logo-canalplus: (0px, 393px, 0px, -393px, 124px, 32px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-canalplus', );
$logo-dakar-name: 'logo-dakar';
$logo-dakar-x: 0px;
$logo-dakar-y: 307px;
$logo-dakar-offset-x: 0px;
$logo-dakar-offset-y: -307px;
$logo-dakar-width: 64px;
$logo-dakar-height: 81px;
$logo-dakar-total-width: 487px;
$logo-dakar-total-height: 425px;
$logo-dakar-image: '../medias/images/css/sprite.png';
$logo-dakar: (0px, 307px, 0px, -307px, 64px, 81px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-dakar', );
$logo-eurosport-name: 'logo-eurosport';
$logo-eurosport-x: 146px;
$logo-eurosport-y: 0px;
$logo-eurosport-offset-x: -146px;
$logo-eurosport-offset-y: 0px;
$logo-eurosport-width: 124px;
$logo-eurosport-height: 76px;
$logo-eurosport-total-width: 487px;
$logo-eurosport-total-height: 425px;
$logo-eurosport-image: '../medias/images/css/sprite.png';
$logo-eurosport: (146px, 0px, -146px, 0px, 124px, 76px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-eurosport', );
$logo-fai-name: 'logo-fai';
$logo-fai-x: 275px;
$logo-fai-y: 0px;
$logo-fai-offset-x: -275px;
$logo-fai-offset-y: 0px;
$logo-fai-width: 141px;
$logo-fai-height: 53px;
$logo-fai-total-width: 487px;
$logo-fai-total-height: 425px;
$logo-fai-image: '../medias/images/css/sprite.png';
$logo-fai: (275px, 0px, -275px, 0px, 141px, 53px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-fai', );
$logo-ffsa-name: 'logo-ffsa';
$logo-ffsa-x: 0px;
$logo-ffsa-y: 270px;
$logo-ffsa-offset-x: 0px;
$logo-ffsa-offset-y: -270px;
$logo-ffsa-width: 162px;
$logo-ffsa-height: 32px;
$logo-ffsa-total-width: 487px;
$logo-ffsa-total-height: 425px;
$logo-ffsa-image: '../medias/images/css/sprite.png';
$logo-ffsa: (0px, 270px, 0px, -270px, 162px, 32px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-ffsa', );
$logo-fia-name: 'logo-fia';
$logo-fia-x: 138px;
$logo-fia-y: 307px;
$logo-fia-offset-x: -138px;
$logo-fia-offset-y: -307px;
$logo-fia-width: 81px;
$logo-fia-height: 56px;
$logo-fia-total-width: 487px;
$logo-fia-total-height: 425px;
$logo-fia-image: '../medias/images/css/sprite.png';
$logo-fia: (138px, 307px, -138px, -307px, 81px, 56px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-fia', );
$logo-ford-name: 'logo-ford';
$logo-ford-x: 275px;
$logo-ford-y: 171px;
$logo-ford-offset-x: -275px;
$logo-ford-offset-y: -171px;
$logo-ford-width: 118px;
$logo-ford-height: 46px;
$logo-ford-total-width: 487px;
$logo-ford-total-height: 425px;
$logo-ford-image: '../medias/images/css/sprite.png';
$logo-ford: (275px, 171px, -275px, -171px, 118px, 46px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-ford', );
$logo-fuji-name: 'logo-fuji';
$logo-fuji-x: 129px;
$logo-fuji-y: 393px;
$logo-fuji-offset-x: -129px;
$logo-fuji-offset-y: -393px;
$logo-fuji-width: 135px;
$logo-fuji-height: 24px;
$logo-fuji-total-width: 487px;
$logo-fuji-total-height: 425px;
$logo-fuji-image: '../medias/images/css/sprite.png';
$logo-fuji: (129px, 393px, -129px, -393px, 135px, 24px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-fuji', );
$logo-hommel-name: 'logo-hommel';
$logo-hommel-x: 132px;
$logo-hommel-y: 160px;
$logo-hommel-offset-x: -132px;
$logo-hommel-offset-y: -160px;
$logo-hommel-width: 113px;
$logo-hommel-height: 40px;
$logo-hommel-total-width: 487px;
$logo-hommel-total-height: 425px;
$logo-hommel-image: '../medias/images/css/sprite.png';
$logo-hommel: (132px, 160px, -132px, -160px, 113px, 40px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-hommel', );
$logo-honda-name: 'logo-honda';
$logo-honda-x: 224px;
$logo-honda-y: 342px;
$logo-honda-offset-x: -224px;
$logo-honda-offset-y: -342px;
$logo-honda-width: 138px;
$logo-honda-height: 18px;
$logo-honda-total-width: 487px;
$logo-honda-total-height: 425px;
$logo-honda-image: '../medias/images/css/sprite.png';
$logo-honda: (224px, 342px, -224px, -342px, 138px, 18px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-honda', );
$logo-len-name: 'logo-len';
$logo-len-x: 421px;
$logo-len-y: 71px;
$logo-len-offset-x: -421px;
$logo-len-offset-y: -71px;
$logo-len-width: 65px;
$logo-len-height: 65px;
$logo-len-total-width: 487px;
$logo-len-total-height: 425px;
$logo-len-image: '../medias/images/css/sprite.png';
$logo-len: (421px, 71px, -421px, -71px, 65px, 65px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-len', );
$logo-lequipe-name: 'logo-lequipe';
$logo-lequipe-x: 167px;
$logo-lequipe-y: 270px;
$logo-lequipe-offset-x: -167px;
$logo-lequipe-offset-y: -270px;
$logo-lequipe-width: 147px;
$logo-lequipe-height: 28px;
$logo-lequipe-total-width: 487px;
$logo-lequipe-total-height: 425px;
$logo-lequipe-image: '../medias/images/css/sprite.png';
$logo-lequipe: (167px, 270px, -167px, -270px, 147px, 28px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-lequipe', );
$logo-ligier-name: 'logo-ligier';
$logo-ligier-x: 0px;
$logo-ligier-y: 0px;
$logo-ligier-offset-x: 0px;
$logo-ligier-offset-y: 0px;
$logo-ligier-width: 141px;
$logo-ligier-height: 68px;
$logo-ligier-total-width: 487px;
$logo-ligier-total-height: 425px;
$logo-ligier-image: '../medias/images/css/sprite.png';
$logo-ligier: (0px, 0px, 0px, 0px, 141px, 68px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-ligier', );
$logo-peugeot-name: 'logo-peugeot';
$logo-peugeot-x: 0px;
$logo-peugeot-y: 73px;
$logo-peugeot-offset-x: 0px;
$logo-peugeot-offset-y: -73px;
$logo-peugeot-width: 116px;
$logo-peugeot-height: 82px;
$logo-peugeot-total-width: 487px;
$logo-peugeot-total-height: 425px;
$logo-peugeot-image: '../medias/images/css/sprite.png';
$logo-peugeot: (0px, 73px, 0px, -73px, 116px, 82px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-peugeot', );
$logo-pmu-name: 'logo-pmu';
$logo-pmu-x: 146px;
$logo-pmu-y: 81px;
$logo-pmu-offset-x: -146px;
$logo-pmu-offset-y: -81px;
$logo-pmu-width: 107px;
$logo-pmu-height: 50px;
$logo-pmu-total-width: 487px;
$logo-pmu-total-height: 425px;
$logo-pmu-image: '../medias/images/css/sprite.png';
$logo-pmu: (146px, 81px, -146px, -81px, 107px, 50px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-pmu', );
$logo-redbull-name: 'logo-redbull';
$logo-redbull-x: 0px;
$logo-redbull-y: 160px;
$logo-redbull-offset-x: 0px;
$logo-redbull-offset-y: -160px;
$logo-redbull-width: 127px;
$logo-redbull-height: 60px;
$logo-redbull-total-width: 487px;
$logo-redbull-total-height: 425px;
$logo-redbull-image: '../medias/images/css/sprite.png';
$logo-redbull: (0px, 160px, 0px, -160px, 127px, 60px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-redbull', );
$logo-renault-name: 'logo-renault';
$logo-renault-x: 187px;
$logo-renault-y: 225px;
$logo-renault-offset-x: -187px;
$logo-renault-offset-y: -225px;
$logo-renault-width: 172px;
$logo-renault-height: 40px;
$logo-renault-total-width: 487px;
$logo-renault-total-height: 425px;
$logo-renault-image: '../medias/images/css/sprite.png';
$logo-renault: (187px, 225px, -187px, -225px, 172px, 40px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-renault', );
$logo-safran-name: 'logo-safran';
$logo-safran-x: 0px;
$logo-safran-y: 225px;
$logo-safran-offset-x: 0px;
$logo-safran-offset-y: -225px;
$logo-safran-width: 182px;
$logo-safran-height: 40px;
$logo-safran-total-width: 487px;
$logo-safran-total-height: 425px;
$logo-safran-image: '../medias/images/css/sprite.png';
$logo-safran: (0px, 225px, 0px, -225px, 182px, 40px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-safran', );
$logo-total-name: 'logo-total';
$logo-total-x: 69px;
$logo-total-y: 307px;
$logo-total-offset-x: -69px;
$logo-total-offset-y: -307px;
$logo-total-width: 64px;
$logo-total-height: 80px;
$logo-total-total-width: 487px;
$logo-total-total-height: 425px;
$logo-total-image: '../medias/images/css/sprite.png';
$logo-total: (69px, 307px, -69px, -307px, 64px, 80px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-total', );
$logo-vg-name: 'logo-vg';
$logo-vg-x: 275px;
$logo-vg-y: 58px;
$logo-vg-offset-x: -275px;
$logo-vg-offset-y: -58px;
$logo-vg-width: 115px;
$logo-vg-height: 59px;
$logo-vg-total-width: 487px;
$logo-vg-total-height: 425px;
$logo-vg-image: '../medias/images/css/sprite.png';
$logo-vg: (275px, 58px, -275px, -58px, 115px, 59px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-vg', );
$logo-yamaha-name: 'logo-yamaha';
$logo-yamaha-x: 224px;
$logo-yamaha-y: 307px;
$logo-yamaha-offset-x: -224px;
$logo-yamaha-offset-y: -307px;
$logo-yamaha-width: 132px;
$logo-yamaha-height: 30px;
$logo-yamaha-total-width: 487px;
$logo-yamaha-total-height: 425px;
$logo-yamaha-image: '../medias/images/css/sprite.png';
$logo-yamaha: (224px, 307px, -224px, -307px, 132px, 30px, 487px, 425px, '../medias/images/css/sprite.png', 'logo-yamaha', );
$logo-ac-2x-name: 'logo-ac@2x';
$logo-ac-2x-x: 842px;
$logo-ac-2x-y: 0px;
$logo-ac-2x-offset-x: -842px;
$logo-ac-2x-offset-y: 0px;
$logo-ac-2x-width: 132px;
$logo-ac-2x-height: 132px;
$logo-ac-2x-total-width: 974px;
$logo-ac-2x-total-height: 850px;
$logo-ac-2x-image: 'sprite@2x.png';
$logo-ac-2x: (842px, 0px, -842px, 0px, 132px, 132px, 974px, 850px, 'sprite@2x.png', 'logo-ac@2x', );
$logo-aso-2x-name: 'logo-aso@2x';
$logo-aso-2x-x: 550px;
$logo-aso-2x-y: 244px;
$logo-aso-2x-offset-x: -550px;
$logo-aso-2x-offset-y: -244px;
$logo-aso-2x-width: 276px;
$logo-aso-2x-height: 88px;
$logo-aso-2x-total-width: 974px;
$logo-aso-2x-total-height: 850px;
$logo-aso-2x-image: 'sprite@2x.png';
$logo-aso-2x: (550px, 244px, -550px, -244px, 276px, 88px, 974px, 850px, 'sprite@2x.png', 'logo-aso@2x', );
$logo-canalplus-2x-name: 'logo-canalplus@2x';
$logo-canalplus-2x-x: 0px;
$logo-canalplus-2x-y: 786px;
$logo-canalplus-2x-offset-x: 0px;
$logo-canalplus-2x-offset-y: -786px;
$logo-canalplus-2x-width: 248px;
$logo-canalplus-2x-height: 64px;
$logo-canalplus-2x-total-width: 974px;
$logo-canalplus-2x-total-height: 850px;
$logo-canalplus-2x-image: 'sprite@2x.png';
$logo-canalplus-2x: (0px, 786px, 0px, -786px, 248px, 64px, 974px, 850px, 'sprite@2x.png', 'logo-canalplus@2x', );
$logo-dakar-2x-name: 'logo-dakar@2x';
$logo-dakar-2x-x: 0px;
$logo-dakar-2x-y: 614px;
$logo-dakar-2x-offset-x: 0px;
$logo-dakar-2x-offset-y: -614px;
$logo-dakar-2x-width: 128px;
$logo-dakar-2x-height: 162px;
$logo-dakar-2x-total-width: 974px;
$logo-dakar-2x-total-height: 850px;
$logo-dakar-2x-image: 'sprite@2x.png';
$logo-dakar-2x: (0px, 614px, 0px, -614px, 128px, 162px, 974px, 850px, 'sprite@2x.png', 'logo-dakar@2x', );
$logo-eurosport-2x-name: 'logo-eurosport@2x';
$logo-eurosport-2x-x: 292px;
$logo-eurosport-2x-y: 0px;
$logo-eurosport-2x-offset-x: -292px;
$logo-eurosport-2x-offset-y: 0px;
$logo-eurosport-2x-width: 248px;
$logo-eurosport-2x-height: 152px;
$logo-eurosport-2x-total-width: 974px;
$logo-eurosport-2x-total-height: 850px;
$logo-eurosport-2x-image: 'sprite@2x.png';
$logo-eurosport-2x: (292px, 0px, -292px, 0px, 248px, 152px, 974px, 850px, 'sprite@2x.png', 'logo-eurosport@2x', );
$logo-fai-2x-name: 'logo-fai@2x';
$logo-fai-2x-x: 550px;
$logo-fai-2x-y: 0px;
$logo-fai-2x-offset-x: -550px;
$logo-fai-2x-offset-y: 0px;
$logo-fai-2x-width: 282px;
$logo-fai-2x-height: 106px;
$logo-fai-2x-total-width: 974px;
$logo-fai-2x-total-height: 850px;
$logo-fai-2x-image: 'sprite@2x.png';
$logo-fai-2x: (550px, 0px, -550px, 0px, 282px, 106px, 974px, 850px, 'sprite@2x.png', 'logo-fai@2x', );
$logo-ffsa-2x-name: 'logo-ffsa@2x';
$logo-ffsa-2x-x: 0px;
$logo-ffsa-2x-y: 540px;
$logo-ffsa-2x-offset-x: 0px;
$logo-ffsa-2x-offset-y: -540px;
$logo-ffsa-2x-width: 324px;
$logo-ffsa-2x-height: 64px;
$logo-ffsa-2x-total-width: 974px;
$logo-ffsa-2x-total-height: 850px;
$logo-ffsa-2x-image: 'sprite@2x.png';
$logo-ffsa-2x: (0px, 540px, 0px, -540px, 324px, 64px, 974px, 850px, 'sprite@2x.png', 'logo-ffsa@2x', );
$logo-fia-2x-name: 'logo-fia@2x';
$logo-fia-2x-x: 276px;
$logo-fia-2x-y: 614px;
$logo-fia-2x-offset-x: -276px;
$logo-fia-2x-offset-y: -614px;
$logo-fia-2x-width: 162px;
$logo-fia-2x-height: 112px;
$logo-fia-2x-total-width: 974px;
$logo-fia-2x-total-height: 850px;
$logo-fia-2x-image: 'sprite@2x.png';
$logo-fia-2x: (276px, 614px, -276px, -614px, 162px, 112px, 974px, 850px, 'sprite@2x.png', 'logo-fia@2x', );
$logo-ford-2x-name: 'logo-ford@2x';
$logo-ford-2x-x: 550px;
$logo-ford-2x-y: 342px;
$logo-ford-2x-offset-x: -550px;
$logo-ford-2x-offset-y: -342px;
$logo-ford-2x-width: 236px;
$logo-ford-2x-height: 92px;
$logo-ford-2x-total-width: 974px;
$logo-ford-2x-total-height: 850px;
$logo-ford-2x-image: 'sprite@2x.png';
$logo-ford-2x: (550px, 342px, -550px, -342px, 236px, 92px, 974px, 850px, 'sprite@2x.png', 'logo-ford@2x', );
$logo-fuji-2x-name: 'logo-fuji@2x';
$logo-fuji-2x-x: 258px;
$logo-fuji-2x-y: 786px;
$logo-fuji-2x-offset-x: -258px;
$logo-fuji-2x-offset-y: -786px;
$logo-fuji-2x-width: 270px;
$logo-fuji-2x-height: 48px;
$logo-fuji-2x-total-width: 974px;
$logo-fuji-2x-total-height: 850px;
$logo-fuji-2x-image: 'sprite@2x.png';
$logo-fuji-2x: (258px, 786px, -258px, -786px, 270px, 48px, 974px, 850px, 'sprite@2x.png', 'logo-fuji@2x', );
$logo-hommel-2x-name: 'logo-hommel@2x';
$logo-hommel-2x-x: 264px;
$logo-hommel-2x-y: 320px;
$logo-hommel-2x-offset-x: -264px;
$logo-hommel-2x-offset-y: -320px;
$logo-hommel-2x-width: 226px;
$logo-hommel-2x-height: 80px;
$logo-hommel-2x-total-width: 974px;
$logo-hommel-2x-total-height: 850px;
$logo-hommel-2x-image: 'sprite@2x.png';
$logo-hommel-2x: (264px, 320px, -264px, -320px, 226px, 80px, 974px, 850px, 'sprite@2x.png', 'logo-hommel@2x', );
$logo-honda-2x-name: 'logo-honda@2x';
$logo-honda-2x-x: 448px;
$logo-honda-2x-y: 684px;
$logo-honda-2x-offset-x: -448px;
$logo-honda-2x-offset-y: -684px;
$logo-honda-2x-width: 276px;
$logo-honda-2x-height: 36px;
$logo-honda-2x-total-width: 974px;
$logo-honda-2x-total-height: 850px;
$logo-honda-2x-image: 'sprite@2x.png';
$logo-honda-2x: (448px, 684px, -448px, -684px, 276px, 36px, 974px, 850px, 'sprite@2x.png', 'logo-honda@2x', );
$logo-len-2x-name: 'logo-len@2x';
$logo-len-2x-x: 842px;
$logo-len-2x-y: 142px;
$logo-len-2x-offset-x: -842px;
$logo-len-2x-offset-y: -142px;
$logo-len-2x-width: 130px;
$logo-len-2x-height: 130px;
$logo-len-2x-total-width: 974px;
$logo-len-2x-total-height: 850px;
$logo-len-2x-image: 'sprite@2x.png';
$logo-len-2x: (842px, 142px, -842px, -142px, 130px, 130px, 974px, 850px, 'sprite@2x.png', 'logo-len@2x', );
$logo-lequipe-2x-name: 'logo-lequipe@2x';
$logo-lequipe-2x-x: 334px;
$logo-lequipe-2x-y: 540px;
$logo-lequipe-2x-offset-x: -334px;
$logo-lequipe-2x-offset-y: -540px;
$logo-lequipe-2x-width: 294px;
$logo-lequipe-2x-height: 56px;
$logo-lequipe-2x-total-width: 974px;
$logo-lequipe-2x-total-height: 850px;
$logo-lequipe-2x-image: 'sprite@2x.png';
$logo-lequipe-2x: (334px, 540px, -334px, -540px, 294px, 56px, 974px, 850px, 'sprite@2x.png', 'logo-lequipe@2x', );
$logo-ligier-2x-name: 'logo-ligier@2x';
$logo-ligier-2x-x: 0px;
$logo-ligier-2x-y: 0px;
$logo-ligier-2x-offset-x: 0px;
$logo-ligier-2x-offset-y: 0px;
$logo-ligier-2x-width: 282px;
$logo-ligier-2x-height: 136px;
$logo-ligier-2x-total-width: 974px;
$logo-ligier-2x-total-height: 850px;
$logo-ligier-2x-image: 'sprite@2x.png';
$logo-ligier-2x: (0px, 0px, 0px, 0px, 282px, 136px, 974px, 850px, 'sprite@2x.png', 'logo-ligier@2x', );
$logo-peugeot-2x-name: 'logo-peugeot@2x';
$logo-peugeot-2x-x: 0px;
$logo-peugeot-2x-y: 146px;
$logo-peugeot-2x-offset-x: 0px;
$logo-peugeot-2x-offset-y: -146px;
$logo-peugeot-2x-width: 232px;
$logo-peugeot-2x-height: 164px;
$logo-peugeot-2x-total-width: 974px;
$logo-peugeot-2x-total-height: 850px;
$logo-peugeot-2x-image: 'sprite@2x.png';
$logo-peugeot-2x: (0px, 146px, 0px, -146px, 232px, 164px, 974px, 850px, 'sprite@2x.png', 'logo-peugeot@2x', );
$logo-pmu-2x-name: 'logo-pmu@2x';
$logo-pmu-2x-x: 292px;
$logo-pmu-2x-y: 162px;
$logo-pmu-2x-offset-x: -292px;
$logo-pmu-2x-offset-y: -162px;
$logo-pmu-2x-width: 214px;
$logo-pmu-2x-height: 100px;
$logo-pmu-2x-total-width: 974px;
$logo-pmu-2x-total-height: 850px;
$logo-pmu-2x-image: 'sprite@2x.png';
$logo-pmu-2x: (292px, 162px, -292px, -162px, 214px, 100px, 974px, 850px, 'sprite@2x.png', 'logo-pmu@2x', );
$logo-redbull-2x-name: 'logo-redbull@2x';
$logo-redbull-2x-x: 0px;
$logo-redbull-2x-y: 320px;
$logo-redbull-2x-offset-x: 0px;
$logo-redbull-2x-offset-y: -320px;
$logo-redbull-2x-width: 254px;
$logo-redbull-2x-height: 120px;
$logo-redbull-2x-total-width: 974px;
$logo-redbull-2x-total-height: 850px;
$logo-redbull-2x-image: 'sprite@2x.png';
$logo-redbull-2x: (0px, 320px, 0px, -320px, 254px, 120px, 974px, 850px, 'sprite@2x.png', 'logo-redbull@2x', );
$logo-renault-2x-name: 'logo-renault@2x';
$logo-renault-2x-x: 374px;
$logo-renault-2x-y: 450px;
$logo-renault-2x-offset-x: -374px;
$logo-renault-2x-offset-y: -450px;
$logo-renault-2x-width: 344px;
$logo-renault-2x-height: 80px;
$logo-renault-2x-total-width: 974px;
$logo-renault-2x-total-height: 850px;
$logo-renault-2x-image: 'sprite@2x.png';
$logo-renault-2x: (374px, 450px, -374px, -450px, 344px, 80px, 974px, 850px, 'sprite@2x.png', 'logo-renault@2x', );
$logo-safran-2x-name: 'logo-safran@2x';
$logo-safran-2x-x: 0px;
$logo-safran-2x-y: 450px;
$logo-safran-2x-offset-x: 0px;
$logo-safran-2x-offset-y: -450px;
$logo-safran-2x-width: 364px;
$logo-safran-2x-height: 80px;
$logo-safran-2x-total-width: 974px;
$logo-safran-2x-total-height: 850px;
$logo-safran-2x-image: 'sprite@2x.png';
$logo-safran-2x: (0px, 450px, 0px, -450px, 364px, 80px, 974px, 850px, 'sprite@2x.png', 'logo-safran@2x', );
$logo-total-2x-name: 'logo-total@2x';
$logo-total-2x-x: 138px;
$logo-total-2x-y: 614px;
$logo-total-2x-offset-x: -138px;
$logo-total-2x-offset-y: -614px;
$logo-total-2x-width: 128px;
$logo-total-2x-height: 160px;
$logo-total-2x-total-width: 974px;
$logo-total-2x-total-height: 850px;
$logo-total-2x-image: 'sprite@2x.png';
$logo-total-2x: (138px, 614px, -138px, -614px, 128px, 160px, 974px, 850px, 'sprite@2x.png', 'logo-total@2x', );
$logo-vg-2x-name: 'logo-vg@2x';
$logo-vg-2x-x: 550px;
$logo-vg-2x-y: 116px;
$logo-vg-2x-offset-x: -550px;
$logo-vg-2x-offset-y: -116px;
$logo-vg-2x-width: 230px;
$logo-vg-2x-height: 118px;
$logo-vg-2x-total-width: 974px;
$logo-vg-2x-total-height: 850px;
$logo-vg-2x-image: 'sprite@2x.png';
$logo-vg-2x: (550px, 116px, -550px, -116px, 230px, 118px, 974px, 850px, 'sprite@2x.png', 'logo-vg@2x', );
$logo-yamaha-2x-name: 'logo-yamaha@2x';
$logo-yamaha-2x-x: 448px;
$logo-yamaha-2x-y: 614px;
$logo-yamaha-2x-offset-x: -448px;
$logo-yamaha-2x-offset-y: -614px;
$logo-yamaha-2x-width: 264px;
$logo-yamaha-2x-height: 60px;
$logo-yamaha-2x-total-width: 974px;
$logo-yamaha-2x-total-height: 850px;
$logo-yamaha-2x-image: 'sprite@2x.png';
$logo-yamaha-2x: (448px, 614px, -448px, -614px, 264px, 60px, 974px, 850px, 'sprite@2x.png', 'logo-yamaha@2x', );
$spritesheet-width: 487px;
$spritesheet-height: 425px;
$spritesheet-image: '../medias/images/css/sprite.png';
$spritesheet-sprites: ($logo-ac, $logo-aso, $logo-canalplus, $logo-dakar, $logo-eurosport, $logo-fai, $logo-ffsa, $logo-fia, $logo-ford, $logo-fuji, $logo-hommel, $logo-honda, $logo-len, $logo-lequipe, $logo-ligier, $logo-peugeot, $logo-pmu, $logo-redbull, $logo-renault, $logo-safran, $logo-total, $logo-vg, $logo-yamaha, );
$spritesheet: (487px, 425px, '../medias/images/css/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 974px;
$retina-spritesheet-height: 850px;
$retina-spritesheet-image: 'sprite@2x.png';
$retina-spritesheet-sprites: ($logo-ac-2x, $logo-aso-2x, $logo-canalplus-2x, $logo-dakar-2x, $logo-eurosport-2x, $logo-fai-2x, $logo-ffsa-2x, $logo-fia-2x, $logo-ford-2x, $logo-fuji-2x, $logo-hommel-2x, $logo-honda-2x, $logo-len-2x, $logo-lequipe-2x, $logo-ligier-2x, $logo-peugeot-2x, $logo-pmu-2x, $logo-redbull-2x, $logo-renault-2x, $logo-safran-2x, $logo-total-2x, $logo-vg-2x, $logo-yamaha-2x, );
$retina-spritesheet: (974px, 850px, 'sprite@2x.png', $retina-spritesheet-sprites, );

/*
These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
*/
$logo-ac-group-name: 'logo-ac';
$logo-ac-group: ('logo-ac', $logo-ac, $logo-ac-2x, );
$logo-aso-group-name: 'logo-aso';
$logo-aso-group: ('logo-aso', $logo-aso, $logo-aso-2x, );
$logo-canalplus-group-name: 'logo-canalplus';
$logo-canalplus-group: ('logo-canalplus', $logo-canalplus, $logo-canalplus-2x, );
$logo-dakar-group-name: 'logo-dakar';
$logo-dakar-group: ('logo-dakar', $logo-dakar, $logo-dakar-2x, );
$logo-eurosport-group-name: 'logo-eurosport';
$logo-eurosport-group: ('logo-eurosport', $logo-eurosport, $logo-eurosport-2x, );
$logo-fai-group-name: 'logo-fai';
$logo-fai-group: ('logo-fai', $logo-fai, $logo-fai-2x, );
$logo-ffsa-group-name: 'logo-ffsa';
$logo-ffsa-group: ('logo-ffsa', $logo-ffsa, $logo-ffsa-2x, );
$logo-fia-group-name: 'logo-fia';
$logo-fia-group: ('logo-fia', $logo-fia, $logo-fia-2x, );
$logo-ford-group-name: 'logo-ford';
$logo-ford-group: ('logo-ford', $logo-ford, $logo-ford-2x, );
$logo-fuji-group-name: 'logo-fuji';
$logo-fuji-group: ('logo-fuji', $logo-fuji, $logo-fuji-2x, );
$logo-hommel-group-name: 'logo-hommel';
$logo-hommel-group: ('logo-hommel', $logo-hommel, $logo-hommel-2x, );
$logo-honda-group-name: 'logo-honda';
$logo-honda-group: ('logo-honda', $logo-honda, $logo-honda-2x, );
$logo-len-group-name: 'logo-len';
$logo-len-group: ('logo-len', $logo-len, $logo-len-2x, );
$logo-lequipe-group-name: 'logo-lequipe';
$logo-lequipe-group: ('logo-lequipe', $logo-lequipe, $logo-lequipe-2x, );
$logo-ligier-group-name: 'logo-ligier';
$logo-ligier-group: ('logo-ligier', $logo-ligier, $logo-ligier-2x, );
$logo-peugeot-group-name: 'logo-peugeot';
$logo-peugeot-group: ('logo-peugeot', $logo-peugeot, $logo-peugeot-2x, );
$logo-pmu-group-name: 'logo-pmu';
$logo-pmu-group: ('logo-pmu', $logo-pmu, $logo-pmu-2x, );
$logo-redbull-group-name: 'logo-redbull';
$logo-redbull-group: ('logo-redbull', $logo-redbull, $logo-redbull-2x, );
$logo-renault-group-name: 'logo-renault';
$logo-renault-group: ('logo-renault', $logo-renault, $logo-renault-2x, );
$logo-safran-group-name: 'logo-safran';
$logo-safran-group: ('logo-safran', $logo-safran, $logo-safran-2x, );
$logo-total-group-name: 'logo-total';
$logo-total-group: ('logo-total', $logo-total, $logo-total-2x, );
$logo-vg-group-name: 'logo-vg';
$logo-vg-group: ('logo-vg', $logo-vg, $logo-vg-2x, );
$logo-yamaha-group-name: 'logo-yamaha';
$logo-yamaha-group: ('logo-yamaha', $logo-yamaha, $logo-yamaha-2x, );
$retina-groups: ($logo-ac-group, $logo-aso-group, $logo-canalplus-group, $logo-dakar-group, $logo-eurosport-group, $logo-fai-group, $logo-ffsa-group, $logo-fia-group, $logo-ford-group, $logo-fuji-group, $logo-hommel-group, $logo-honda-group, $logo-len-group, $logo-lequipe-group, $logo-ligier-group, $logo-peugeot-group, $logo-pmu-group, $logo-redbull-group, $logo-renault-group, $logo-safran-group, $logo-total-group, $logo-vg-group, $logo-yamaha-group, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
  It should be used with a "retina group" variable.

The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/

$icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );

.icon-home {
  @include retina-sprite($icon-home-group);
}
*/
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

/*
The `retina-sprites` mixin generates a CSS rule and media query for retina groups
  This yields the same output as CSS retina template but can be overridden in SCSS

@include retina-sprites($retina-groups);
*/
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
