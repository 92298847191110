.talent-intro{
	.grid{
		position:relative;
	}

	h2{
		width: calc(100% - 80px);
	}

	.nav{
		position:absolute;
		top:100px;right:0;
		// transform:translate(0, -50%);
		text-align:center;

		.nav-btns{
			font-size:0;
			line-height:1em;

			.nav-btn{
				display:inline-block;
				cursor:pointer;

				svg{
					width:11px;height:11px;
				}
				&.nav-left{
					margin-right:25px;
				}
			}
		}
		.nav-infos{
			margin-top:35px;
			line-height:1em;

			span{
				line-height:1em;

				em{
					margin-left:12px;

					&:first-child{
						margin-right:12px;
						margin-left:0;
					}
				}
			}
		}

		@include tablet {
			.nav-btns{
				.nav-btn{
					width:33px;
					padding:10px 0;
					margin-top:-10px;
					text-align:center;

					svg{
						display:inline-block;
						pointer-events:none;
					}
					&.nav-left{
						margin-right:0;
						margin-left:-11px;
					}
					&.nav-right{
						margin-right:-11px;
					}
				}
			}

			.nav-infos{
				margin-top:25px;
			}
		}
		@include mobile {
			position:static;
			text-align:left;
			margin-bottom:24px;
			transform:translate(0, -50%);

			.nav-btns{
				position:absolute;
				top:50%;right:0;
				transform:translate(0, -50%);

				.nav-btn{
					width:45px;
					padding:10px 0;
					text-align:center;

					svg{
						display:inline-block;
						width:16px;height:16px;
						pointer-events:none;
					}
					&.nav-left{
						margin-right:0;
						margin-left:-15px;
					}
					&.nav-right{
						margin-right:-15px;
					}
				}
			}

		}
	}
}
