// -------------------------o Icons

$icons:
	('logo-ac', $logo-ac)
	('logo-ligier', $logo-ligier)
	('logo-aso', $logo-aso)
	('logo-canalplus', $logo-canalplus)
	('logo-dakar', $logo-dakar)
	('logo-eurosport', $logo-eurosport)
	('logo-fai', $logo-fai)
	('logo-ffsa', $logo-ffsa)
	('logo-fia', $logo-fia)
	('logo-ford', $logo-ford)
	('logo-fuji', $logo-fuji)
	('logo-hommel', $logo-hommel)
	('logo-honda', $logo-honda)
	('logo-len', $logo-len)
	('logo-lequipe', $logo-lequipe)
	('logo-peugeot', $logo-peugeot)
	('logo-pmu', $logo-pmu)
	('logo-redbull', $logo-redbull)
	('logo-renault', $logo-renault)
	('logo-safran', $logo-safran)
	('logo-total', $logo-total)
	('logo-vg', $logo-vg)
	('logo-yamaha', $logo-yamaha);


@each $ico in $icons{

	.#{nth($ico, 1)}{
		display: block;
		@include sprite(nth($ico, 2));
	}

}


.ico{
	width: 12px;
	height: 12px;
	display: block;
	svg{
		display: block;
		width: 100%;
		height: 100%;
		fill: $color-white;
	}
}


.ico-prev, .ico-next, .ico-close, .ico-scroll-to, .ico-play{

	&.light{
		i,
		&:before,
		&:after{
			background-color: $color-white;
		}
	}

	position: absolute;
	width: 20px;
	height: 20px;
	left: 50%;
	top: 50%;
	margin-top: -10px;
	margin-left: -10px;
	i,
	&:before,
	&:after{
		position: absolute;
		content:'';
		display: block;
		background-color: $color-black;
		height: 3px;
		border-radius:30%;
		top: 50%;
		left: 50%;
		margin-top: -1px;
	}

	&:before,
	&:after{
		width: 12px;
		margin-left: -6px;
	}

	i{
		width: 14px;
		margin-left: -7px;
	}
}


.ico-play{
	&:before,
	&:after{
		display: none;
	}
}

.ico-close{
	i{
		display: none;
	}
	svg{
		display: none;
	}
	&:before{
		transform: rotate(45deg);
	}
	&:after{
		transform: rotate(-45deg);
	}
}

html.ie{

}

.ico-prev, .ico-scroll-to{
	&:before,
	&:after{
		width: 10px;
		margin-left: -8px;
	}
	&:before{
		transform-origin: left bottom;
		transform: rotate(45deg);
		margin-top: -2px;
	}
	&:after{
		transform-origin: left top;
		transform: rotate(-45deg);
		margin-top: 0;
	}
}

.ico-next{
	&:before,
	&:after{
		width: 10px;
		margin-left: -2px;
	}
	&:before{
		transform-origin: right bottom;
		transform: rotate(-45deg);
		margin-top: -2px;
	}
	&:after{
		transform-origin: right top;
		transform: rotate(45deg);
		margin-top: 0;
	}
}

.ico-scroll-to{

	pointer-events:none;

	i,
	&:before,
	&:after{
		background-color: $color-white;
	}

}


@include mobile() {
	.ico-play{
		svg{
			fill: white;
		}
	}
}
