body:not(.touch){

	// a:not(.project-nav .txt-med-4), .main-nav li i{
	// 	&:hover{
	// 		opacity: 0.5;
	// 	}
	// 	transition: all 1600ms $ease-OutExpo 0ms;
	// }

	.main-nav li{
		i, a{
			transition: all 1600ms $ease-OutExpo 0ms;
		}
		&:hover{
			i, a{
				color:$color-grey-2;
			}
		}
	}

	.project-nav{
		// a{
		// 	&:hover{
		// 		p, span{
		// 			color: $color-white;
		// 			z-index: 12;
		// 		}
		// 		.img-container{
		// 			opacity: 1;
		// 			transform: scale(1.1);
		// 			transition: all 1600ms $ease-OutExpo 0ms;
		// 		}
		// 	}
		// }
	}

	.overlay{
		ul{
			li{
				&:hover{
					.img{
						opacity: 0.2;
						transform: scale(1.1);
						transition: all 1600ms $ease-OutExpo 0ms;
					}
				}
			}
		}
	}

	.carousel:not(.full){

		.previous{
			.ico-prev{
				&:first-child{
					transition: transform 0ms, opacity 0ms;

					i, &:before, &:after{
						transition: background-color 300ms $ease-InExpo 0ms;
						background-color:$color-black;
					}
				}
				&:last-child{
					transition: transform 0ms, opacity 0ms;
					transform: translate(60px, 0);
					opacity:0;

					i, &:before, &:after{
						background-color:$color-grey-3;
					}
				}
			}
			&:hover,
			&.disable{
				.ico-prev{
					&:first-child{
						transition: transform 600ms $ease-InExpo 0ms, opacity 600ms $ease-InExpo 0ms;
						transform: translate(-40px, 0);
						opacity:0;

						i, &:before, &:after{
							transition: background-color 600ms $ease-InExpo 0ms;
							background-color:$color-grey-3;
						}
					}
					&:last-child{
						transition: transform 600ms $ease-InOutCubic 250ms, opacity 600ms $ease-InOutCubic 250ms;
						transform: translate(0, 0);
						opacity:1;
					}
				}
			}
			&.disable{
				cursor:auto;

				.ico-prev{
					&:last-child{
						i, &:before, &:after{
							background-color:$color-grey-1;
						}
					}
				}
			}
		}

		.next{
			.ico-next{
				&:first-child{
					transition: transform 0ms, opacity 0ms;

					i, &:before, &:after{
						background-color:$color-black;
						transition: background-color 300ms $ease-InExpo 0ms;
					}
				}
				&:last-child{
					transition: transform 0ms, opacity 0ms;
					transform: translate(-60px, 0);
					opacity:0;

					i, &:before, &:after{
						background-color:$color-grey-3;
					}
				}
			}
			&:hover,
			&.disable{
				.ico-next{
					&:first-child{
						transition: transform 600ms $ease-InExpo 0ms, opacity 600ms $ease-InExpo 0ms;
						transform: translate(40px, 0);
						opacity:0;

						i, &:before, &:after{
							transition: background-color 600ms $ease-InExpo 0ms;
							background-color:$color-grey-3;
						}
					}
					&:last-child{
						transition: transform 600ms $ease-InOutCubic 250ms, opacity 600ms $ease-InOutCubic 250ms;
						transform: translate(0, 0);
						opacity:1;
					}
				}
			}
			&.disable{
				cursor:auto;

				.ico-next{
					&:last-child{
						i, &:before, &:after{
							background-color:$color-grey-1;
						}
					}
				}
			}
		}

	}

	.carousel.full{

		nav{
			.previous,
			.next{
				> i{
					i,
					&:before,
					&:after{
						transition: background-color 600ms $ease-OutExpo 0ms;
					}
				}
			}
			.previous:hover,
			.next:hover{
				> i{
					i,
					&:before,
					&:after{
						background-color:$color-grey-3;
					}
				}
			}
			.previous.disable:hover,
			.next.disable:hover{
				> i{
					i,
					&:before,
					&:after{
						background-color:$color-grey-1;
					}
				}
			}
		}

	}

	.link-underline{
		position:relative;

		&:before{
			content:'';
			display:block;
			position:absolute;
			height:1px;width:100%;
			bottom:0;left:0;
			transform:scaleX(0);
			transform-origin:right center;
			background-color:$color-white;
			transition:transform 800ms $ease-OutExpo 0ms, transform-origin 0ms $ease-linear 0ms;
		}

		&:hover{
			&:before{
				transform:scaleX(1);
				transform-origin:left center;
			}
		}
	}

	.round-hover{

		&:before{
			content:'';
			display:block;
			position:absolute;
			top:50%;left:50%;
			width:calc(100% - 10px);height:calc(100% - 10px);
			border-radius:100%;
			background-color:$color-white;
			z-index:0;

			transform:translate(-50%,-50%) scale(0);
			transition:all 1200ms $ease-1 0ms;
		}

		.round-hover-ico{
			pointer-events:none;

			&:nth-child(1){
				transition:transform 1200ms $ease-1 0ms, opacity 1200ms $ease-1 0ms;
				transform:translate(-18px,0);
				opacity:0;

				fill:$color-black;

				i,
				&:before,
				&:after{
					background-color:$color-black;
				}
			}
			&:nth-child(2){
				transition:transform 1200ms $ease-1 0ms, opacity 1200ms $ease-1 0ms;
				transform:translate(0,0);
				opacity:1;

				fill:$color-white;

				i,
				&:before,
				&:after{
					background-color:$color-white;
				}
			}
			&:nth-child(3){
				transform:translate(18px,0);
				opacity:0;

				fill:$color-black;

				i,
				&:before,
				&:after{
					background-color:$color-black;
				}
			}

		}

		&:hover{

			&:before{
				transform:translate(-50%,-50%) scale(1);
			}

		}

		&.hover{

			.round-hover-ico{
				&:nth-child(1){
					fill:$color-white;

					i,
					&:before,
					&:after{
						background-color:$color-white;
					}
				}
				&:nth-child(2){
					fill:$color-black;

					i,
					&:before,
					&:after{
						background-color:$color-black;
					}
				}
				&:nth-child(3){
					fill:$color-white;

					i,
					&:before,
					&:after{
						background-color:$color-white;
					}
				}

			}

		}

		&.close-overlay{

			.round-hover-ico{
				&:nth-child(1){
					i,
					&:before,
					&:after{
						background-color:$color-grey-5;
					}
				}
				&:nth-child(3){
					i,
					&:before,
					&:after{
						background-color:$color-grey-5;
					}
				}

			}

			&.hover{

				.round-hover-ico{
					&:nth-child(2){
						i,
						&:before,
						&:after{
							background-color:$color-grey-5;
						}
					}
				}

			}

		}

	}

	.overlay{
		&:not(.js-overlay-projects){
			ul.nav{
				li{
					a{
						transition: all 800ms $ease-OutExpo 0ms;
					}
				}
				&:hover{
					li{
						a{
							opacity:0.4;
						}
					}
				}
				li{
					&:hover{
						a{
							opacity:1;
						}
					}
				}

			}
		}
		&.js-overlay-projects{
			ul.nav{
				> span{
					li{
						span{
							transition: all 800ms $ease-OutExpo 0ms;
						}
						a{
							transition: all 800ms $ease-OutExpo 0ms;
						}
					}
				}
				&:hover{
					> span{
						li{
							span{
								opacity:0.3;
							}
							a{
								opacity:0.3;
							}
						}
						&:hover{
							li{
								a{
									opacity:0.7;
								}
								&:hover{
									a{
										opacity:1;
									}
								}

								&.categ{
									span{
										opacity:1;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	form{
		.submit,
		.next{
			transition:opacity 800ms $ease-OutExpo 0ms;

			&:hover{
				opacity:0.6;
			}
		}
	}

	footer{
		.social{
			li{
				a{
					transition:opacity 800ms $ease-OutExpo 0ms;

					&:hover{
						opacity:0.6;
					}
				}
			}
		}
		.others{
			a{
				transition:opacity 800ms $ease-OutExpo 0ms;

				&:hover{
					opacity:0.6;

					&.is-active{
						opacity:1;
					}
				}
			}
		}
	}

	.expertise-talents{
		.thumbs, .filters{
			li{
				&:hover{
					opacity:0.5;
				}
			}
		}
	}

	.heritage-products{
		ul{
			li{
				.btn-black{
					transition:opacity 800ms $ease-OutExpo 0ms;

					&:hover{
						opacity:0.8;
					}
				}
			}
		}
	}

	.talent-intro{
		.nav{
			.nav-btns{
				.nav-btn{
					svg{
						transition:all 800ms $ease-OutExpo 0ms;
					}
					&:hover{
						svg{
							fill:$color-grey-3;
						}
					}
				}
			}
		}
	}

}
