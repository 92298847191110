.about-awards{
	border-bottom: 1px solid $color-grey-1;
	ul{
		@include clearfix();
		position: relative;
		height: 66px;
		li{
			float: left;
			margin: 0 100px 0 0;
			position: relative;
		}
	}

	.cat, .author{
		color: $color-black;
	}

	.author{
		display: block;
		margin-bottom: 20px;
	}

	.col-2-3{
		.img{
			&:first-child{
				margin: 0 0 20px 0;
			}
			margin: 20px 0 20px 0;
		}
	}

	@include mobile {

		h2.pad-1{
			padding-bottom:50px;
		}

		p.pad-5-top{
			padding-top:15px;
		}

		.grid.pad-1{
			padding:50px 0;
		}

		.txt-med-3{
			font-size:$font-size-100 / 4;
		}

		ul{
			height:auto;
			font-size:0;

			li{
				display:inline-block;
				vertical-align:middle;
				float:none;
				width:50%;
				transform: translate3d(0, 0, 0);
				top:0;
				margin:10px 0;
				text-align:right;

				&:nth-child(even){
					padding-right:20px;
				}

				&:nth-child(1),
				&:nth-child(2){
					margin-top:0;
				}

				&:nth-last-child(1),
				&:nth-last-child(2){
					margin-bottom:0;
				}

				i{
					display:inline-block;
				}
			}
		}

		.author{
			margin-bottom: 10px;
		}

	}

}
