.expertise-content{
	.expertise-carousel:last-child{
		&::after{
			content:'';
			display:block;
			width:100%;height:150px;
			background-color:$color-white;
		}
	}
	.col-2-3{
		position: relative;
		span{
			position: absolute;
			right: -49px;
			top: -43px;
			z-index: 0;
		}
		p{
			position: relative;
			z-index: 1;
		}
	}

	@include mobile {
		.grid.pad-1{
			padding-bottom:50px;
		}
		.grid.pad-1-bot{
			padding-bottom:50px;
		}
		.expertise-carousel:last-child{
			&::after{
				height:50px;
			}
		}
		.col-2-3{
			span{
				top: -35px;
				right:0;
			}
		}
	}
}
