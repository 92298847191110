.project-featured{
	position:relative;
	margin-top: -140px;
	width:100%;
	height:calc(100vh);
	overflow:hidden;
	// background-color:$color-black;

	.featured-black-layer{
		position:absolute;
		width:100%;height:100%;
		top:0;left:0;
		z-index:-200;
		background-color:$color-black;
	}

	.viewport{
		position:absolute;
		width:100%;height:100%;
		top:0 !important;
		left:0;

		.parallax{
			position:relative;
			width:100%;height:100%;
			//will-change: transform;

			.img{
				width:100%;height:100%;
				background-color: $color-black;
				transform: scale(1.1);
				opacity:0.2;

				img{
					position:absolute;
					top:50%;left:50%;
					transform:translate(-50%,-50%);
					width:auto;height:auto;
					min-width:100%;min-height:100%;
					transition:all 0ms;
					opacity:1 !important;
				}
			}
		}
	}

	.white-background{
		background-color:transparent;
	}

	.infos{
		position: absolute;
	}

	.infos{
		z-index: 3;
		.title{
			width: 70%;
		}
		> *{
			display:inline-block;
			width:100%;

			opacity:0;
			transform: translate3d(100px, 0, 0);

			&:nth-child(1){
				transition:all 2000ms $ease-OutExpo 100ms;
			}
			&:nth-child(2){
				transition:all 2000ms $ease-OutExpo 200ms;
			}
			&:nth-child(3){
				transition:all 2000ms $ease-OutExpo 300ms;
			}

			&.show{
				opacity:1;
				transform: translate3d(0, 0, 0);
			}
		}
	}

	// PARALLAX

	// .viewport{
	// 	position:fixed;
	// 	z-index:-100;
	// }

	@include tablet {
		height:auto;

		.viewport{
			position:static;
			transform:translate3d(0,0,0) !important;

			.parallax{
				.img{
					height:auto;

					img{
						position:static;
						transform:translate3d(0,0,0);
						width:100%;
						min-width:0;min-height:0;
					}
				}
			}
		}

	}

	@include mobile {
		margin-top: 0;
		height:auto;

		.viewport{
			position:static;
			transform:translate3d(0,0,0) !important;

			.parallax{
				.img{
					opacity:1 !important;
					width:auto;height:auto;

					img{
						position:static;
						transform:translate(0,0);
						width:100%;height:auto;
						min-width:0;min-height:0;
					}
				}
			}
		}

		.white-background{
			position:relative;
			z-index:1;
			background-color:$color-white;
		}

		.infos{
			position:static;
			padding-top:30px;

			&.center{
				transform: translate(0,0);
			}

			span{
				margin-bottom:10px;
				color:$color-black;
			}
			h1{
				color:$color-black;
			}
			.subtitle{
				margin-top:30px;
				color:$color-black;
			}

			> *{
				opacity:1;
				transform: translate3d(0, 0, 0);

				&:nth-child(1){
					transition:all 0ms;
				}
				&:nth-child(2){
					transition:all 0ms;
				}
				&:nth-child(3){
					transition:all 0ms;
				}
			}
		}

	}

}
