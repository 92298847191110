// -------------------------o Body

@include font-smoothing();
@include box-sizing();
@include no-img-selection();
@include font-selection-color( $color-pink, $color-white );
//@include no-text-selection();

html{
	// overflow-y:scroll;
}

body {
	&.no-scroll{
		overflow:hidden;
		height:0;
	}

	#overview{
		padding-top:140px;
	}

	.spinner{
		transition: opacity 800ms $ease-OutExpo 0ms;
		background:url( '../medias/images/others/spinner.gif' ) no-repeat center;
		&.visible{
			opacity:1 !important;
		}

		@include mobile() {
			background:url( '../medias/images/others/spinner-black.gif' ) no-repeat center;
		}
		@include tablet() {
			background:url( '../medias/images/others/spinner-black.gif' ) no-repeat center;
		}
	}

	#fixed{
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		pointer-events: none;
	}

}

a.out-nav{
	display: inline-block;
	position: fixed;
	pointer-events:none;
	&.shown{
		p, span{
			color: $color-white;
			z-index: 12;
		}
		.img-container{
			opacity: 1;
			transform: scale(1.1);
			transition: all 1600ms $ease-OutExpo 0ms;
		}
	}
	p, span{
		position: relative;
		transition: color 1600ms $ease-OutExpo 0ms;
		color: transparent;
	}
	.img-container{
		position: fixed;
		z-index: 11;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		opacity: 0;
		pointer-events:none;
		background-color:$color-black;
		transform: scale(1.2);
		transition: all 1600ms $ease-OutExpo 0ms;
		.img{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity:0.2;
			transition: all 1600ms $ease-OutExpo 0ms;
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: auto;
				height: auto;
				min-width: 100%;
				min-height: 100%;
			}
		}
	}
	&.active{
		p, span{
			color: $color-white !important;
			z-index: 12 !important;
		}
		.img-container{
			opacity: 1 !important;
			transform: scale(1.1) !important;

			.img{
				opacity:0.2;
			}
		}
	}
}


@include mobile() {

	html,body{
		overflow-x:hidden;
	}

	body{
		padding-top: 80px;

		> section{
			overflow:hidden;
			padding-top: 0;
		}
	}

	html.safari{
		.video .play{
			pointer-events:none;
		}
	}

	body #overview{
		padding-top: 0;
	}

	body .home-carousel .images .parallax{
		bottom: auto;
		top: 0;
	}

}


html.fullscreen,
html.fullscreen body,
html.fullscreen #overview{
	width: 100% !important;
	height: 100% !important;
}

html.fullscreen #fixed{
	display: none;
}
