// -------------------------o Colors

$color-black 			:	#000;
$color-white 			:	#fff;

// -------------------------o Easing

$ease-linear 			:	cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease-InQuad 			:	cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ease-InCubic 			:	cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ease-InQuart			:	cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ease-InQuint			:	cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ease-InSine			:	cubic-bezier(0.470, 0.000, 0.745, 0.715);
$ease-InExpo			:	cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ease-InCirc			:	cubic-bezier(0.600, 0.040, 0.980, 0.335);
$ease-InBack			:	cubic-bezier(0.600, 0, 0.735, 0.045);

$ease-OutQuad			:	cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-OutCubic			:	cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ease-OutQuart			:	cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-OutQuint			:	cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-OutSine			:	cubic-bezier(0.390, 0.575, 0.565, 1.000);
$ease-OutExpo			:	cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ease-OutCirc			:	cubic-bezier(0.075, 0.820, 0.165, 1.000);
$ease-OutBack			:	cubic-bezier(0.175, 0.885, 0.320, 1);

$ease-InOutQuad			:	cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-InOutCubic		:	cubic-bezier(0.645, 0.045, 0.355, 1.000);
$ease-InOutQuart		:	cubic-bezier(0.770, 0.000, 0.175, 1.000);
$ease-InOutQuint		:	cubic-bezier(0.860, 0.000, 0.070, 1.000);
$ease-InOutSine			:	cubic-bezier(0.445, 0.050, 0.550, 0.950);
$ease-InOutExpo			:	cubic-bezier(1.000, 0.000, 0.000, 1.000);
$ease-InOutCirc			:	cubic-bezier(0.785, 0.135, 0.150, 0.860);
$ease-InOutBack			:	cubic-bezier(0.680, 0, 0.265, 1);

// -------------------------o Sizes

$size-mobile			:	"only screen and (min-device-width : 320px) and (max-device-width : 700px)";
$size-mobile-landscape	:	"only screen and (orientation: landscape) and (min-device-width : 320px) and (max-device-width : 700px)";
$size-phablet			:	"only screen and (min-device-width : 480px) and (max-device-width : 767px)";
$size-tablet			:	"only screen and (min-device-width : 700px) and (max-device-width : 1024px)";
$size-tablet-portrait	:	"only screen and (orientation: portrait) and (min-device-width : 700px) and (max-device-width : 768px)";
$size-desktop			:	"only screen and (min-width : 1025px)";
$size-big-desktop		:	"only screen and (min-width : 1680px)";
$size-height-730		:	"(max-height : 730px)";
$size-height-768		:	"(max-height : 768px)";
$size-height-850		:	"(max-height : 850px)";
