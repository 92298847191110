// -------------------------o Lists

.pink-chip{
	padding-top:10px;
	padding-right:10px;

	li{
		font-family:"Avenir-Black";
		position: relative;
		padding-left:28px;
		margin-bottom: 20px;

		&:before{
			left: 0;
			display: block;
			content:'';
			position: absolute;
			width: 6px;
			height: 6px;
			background-color: $color-pink;
			border-radius: 6px;
			top:8px;
		}
	}

	@include mobile {
		@include clearfix();
		padding-top:0;
		padding-right:0;

		li{
			padding-left:15px;
			// font-size:0.857em;
			float:none;
			width:100%;
		}
	}

}
