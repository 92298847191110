.submit{
	display: block;
	text-align: right;
	cursor: pointer;
	padding: 10px 20px;
	float: right;
	position: relative;
	top: 55px;
	left: 20px;
}

.btn-black{
	i{
		display: inline-block;
		margin-right: 5px;
	}
	@extend .txt-small-1;
	display:block;
	padding:12px 0 !important;
	text-align:center;
	background-color: $color-black;
	border-radius:26px;
	color: $color-white !important;
	line-height: 2.5em !important;
}

.close, .prev, .scroll-to, .play{
	position: absolute;
	background-color: $color-grey-6;
	border-radius: 100%;
	z-index: 100;
}

.close, .prev, .scroll-to{
	width: 60px;
	height: 60px;
}

.close{
	top: 30px;
	right: 30px;
	cursor: pointer;
}

.prev{
	top: 30px;
	left: 30px;
}

.scroll-to, .play{
	background-color: $color-black;
	cursor:pointer;
	z-index:2;
}

.scroll-to{
	bottom:60px;
	right:50px;
	transform:rotate(-90deg);
}

.play{
	width: 90px;
	height: 90px;
	top:50%;
	left:50%;
	margin-top: -45px;
	margin-left: -45px;
	z-index: 3;
}

@include mobile {
	.submit{
		float: left;
		top: 20px;
		left: -16px;
		position: relative;
	}
	.close{
		top: 15px;
		right: 15px;
	}
	.prev{
		top: 15px;
		left: 15px;
	}
	.scroll-to{
		display:none;
	}
}
