.heritage-products{

	ul{
		li{
			h3{
				display:inline-block;
			}
			span.txt-small-1{
				margin-left:20px;
				font-family:$Avenir-Medium;
				font-size: 14px;
			}
			p{
				padding-right:50px;
			}
		}
	}

	form ul li{
		border-bottom:1px solid $color-grey-1;
		padding: 10px 10px 10px 30px;
		&:last-child{
			border:none;
		}
	}

	input[type="radio"], label{
		line-height:1em;
		display:inline-block;
		vertical-align:middle;
		text-align:left;
	}

	input[type="radio"]{
		position:relative;
		z-index:1;
		opacity:0;
	}

	label{
		position:relative;
		padding-left:16px;
		width:57%;
		line-height:1.3em;

		&:before, &:after{
			content:'';
			position:absolute;
			top:50%;
			transform:translate(0,-50%);
			border-radius:14px;
		}
		&:before{
			left:-18px;
			width:14px;height:14px;
			border:1px solid $color-grey-3;
		}
		&:after{
			left:-17px;
			width:12px;height:12px;
			background-color:$color-black;
			border:2px solid $color-white;
			opacity:0;
		}
	}

	input[type="radio"]:checked + label:after{
		opacity:1;
	}

	a.btn-black{
		i{
			margin-top:-3px;
			width:14px;height:14px;
			vertical-align:middle;
		}
		span{
			vertical-align:middle;
		}
	}

	@include mobile {
		ul{
			li{
				span.txt-small-1{
					display:block;
					margin-left:0;
					margin-top:10px;
				}
				p.pad-4{
					padding-top:30px;
				}
				p{
					padding-right:0;
				}
				form, a{
					max-width:80%;
				}
				form ul li{
					padding: 10px 0 10px 10px;

					*{
						pointer-events:none;
					}
				}
				form{
					label{
						padding-left:10px;
						width:57%;
						font-size:$font-size-12;
					}
					span{
						font-size:$font-size-12;
					}
				}
			}
		}
	}

}
