.talent-mosaic{
	ul{

		li{
			margin-top:55px;

			&:first-child{
				margin-top:0;
			}

			img{
				width:100%;
			}
			h4{
				line-height:1em;
			}
		}
	}

	@include mobile {
		ul{
			li{
				margin-top:30px;

				&:first-child{
					margin-top:30px;
				}

				h4{
					margin-top:5px;
				}
			}
		}
	}
}