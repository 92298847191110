.carousel{

	&.full{

		.col-5-6{
			width: 100%;
		}

		.infos ul li .col-2-3{
			float: none;
		}

		.infos ul li .col-1-3{
			float: none;
			text-align: left;
			display: block;
			margin-top: 15px;
		}

		nav{
			position: relative;
			width: 100px;
			height: 30px;
			margin: 0 auto;
			left: 380px;
			top: -140px;
			margin-bottom:-30px;
			img{
				display: none;
			}
			>div{
				position: inherit;
				height: 40px;
				width: 40px;
				&:last-child{
					top: 0;
				}
				cursor: pointer;
			}
			&.hide{
				display:none;
			}
			.previous.disable,
			.next.disable{
				cursor:auto;
				> i{
					i,
					&:before,
					&:after{
						background-color:$color-grey-1;
					}
				}
			}
		}

		.nav-infos{
			position: absolute;
			left: 759px;
			top: 81px;

			span{
				em{
					&:first-child{
						padding-right:12px;
					}
					&:last-child{
						padding-left:12px;
					}
				}
			}
			&.hide{
				display:none;
			}
		}

	}

	width: 100%;
	position: relative;

	nav{
		position: absolute;
		width: 100%;
		top: 0;
		right: 0;
		>div{
			height: 50%;
			text-align: center;
			position: absolute;
			right: 1px;
			top: 0;
			background-color:$color-white;
			&:last-child{
				top: 50%;
			}
			cursor: pointer;
		}
	}

	&:not(.full){
		nav{
			>div{
				cursor:auto;

				>div{
					position:absolute;
					width:40px;height:40px;
					top:50%;left:50%;
					transform:translate3d(-50%,-50%,0);
					cursor: pointer;
				}

				&:last-child{
					top:0;
				}
				&:nth-last-child(2){
					top:50%;
				}
			}
		}
	}

	.images{
		position: relative;
		overflow: hidden;

		.ratio-mobile{
			display: none;
		}

		.parallax{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 200%;
		}

		.viewport{
			overflow: hidden;
			position: absolute;
			height: 100%;
			top: 0;
			left: 0;

			.statusbar{
				&.progress{
					transform: translate3d(0, 100%, 0);
					transition: all 5000ms $ease-linear 0ms;
				}
				display: block;
				position: absolute;
				width: 5px;
				height: 100%;
				top: -100%;
				right: 0;
				background-color: $color-white;
				z-index: 2;
				transition: all 1000ms $ease-OutExpo 0ms;
			}
			.overview{
				width: 9999px;
				height: 100%;
				position: absolute;
				top: 0;
				//will-change: transform;

				li{
					float: left;
					overflow: hidden;
					position: relative;
					height: 100%;
					.mask{
						width: 100%;
						position: absolute;
						bottom: 0;
						height:100%;

						.img{
							position:absolute;
							top:0;
							width:100%;
							//will-change: transform;

							img{
								//transform:translateZ(0);
							}

						}
					}
					.black-layer{
						position:absolute;
						top:0;left:0;
						height:100%;width:100%;
						background-color:$color-black;
						opacity:0.8;
						//will-change:opacity;
					}
				}
			}
		}

	}

	.infos{
		height: 190px;
		ul{
			width: 100%;
			position: relative;
			height: 100%;
			li{
				&.shown{
					opacity: 1;
					pointer-events: all;
				}
				position: absolute;
				opacity: 0;
				pointer-events: none;
				width: 100%;
				left: 0;
				top: 0;
				transition: opacity 1200ms $ease-OutExpo 0ms;
				.col-1-3{
					text-align: right;
				}

			}
		}
	}

	// PARALLAX

	// .images{
	// 	.viewport{
	// 		position:fixed;
	// 		z-index:-100;
	// 	}
	// }
	// &.full{
	// 	.images{
	// 		.viewport{
	// 			position:fixed;
	// 			z-index:-100;

	// 			.overview{
	// 				li{
	// 					.mask{
	// 						.img{
	// 							bottom:50%;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	@include tablet() {

		.images{
			.viewport{
				position: absolute;
				z-index:0;
			}
		}

		&.full{
			.images{
				.viewport{
					.overview{
						li{
							.mask{
								.img{
									bottom:-25%;
								}
							}
						}
					}
				}
			}
		}

		&:not(.full){

			.previous{
				&.disable{
					.ico-prev{
						&:first-child{
							display:none;
						}
						&:last-child{
							transition: transform 0ms, opacity 600ms $ease-InOutCubic 250ms;
							transform: translate(0, 0);
							opacity:1;

							i, &:before, &:after{
								background-color:$color-grey-2;
							}
						}
					}
				}
			}

			.next{
				&.disable{
					.ico-next{
						&:first-child{
							display:none;
						}
						&:last-child{
							transition: transform 0ms, opacity 600ms $ease-InOutCubic 250ms;
							transform: translate(0, 0);
							opacity:1;

							i, &:before, &:after{
								background-color:$color-grey-2;
							}
						}
					}
				}
			}

		}

		.infos{
			ul{
				li{
					.col-1-3{
						padding-right:72px;
					}
				}
			}
		}

	}

	@include mobile() {

		@include clearfix();

		.images{
			.viewport{
				position: absolute;
				z-index:0;
			}
		}

		&.full{

			nav{
				position: relative;
				width: 100px;
				height: 30px;
				margin: 0;
				left: auto;
				right: -8px;
				top: -44px;
				margin-bottom:-30px;
				img{
					display: none;
				}
				>div{
					position: inherit;
					height: 40px;
					width: 40px;
					&:last-child{
						top: 0;
					}
					cursor: pointer;
				}
			}

			.nav-infos{
				left: auto;
				right:0;
				top: 27px;
			}

			.images{
				.viewport{
					.overview{
						li{
							.mask{
								.img{
									bottom:0;
								}
							}
						}
					}
				}
			}

		}

		&:not(.full){

			.previous{
				&.disable{
					.ico-prev{
						&:first-child{
							display:none;
						}
						&:last-child{
							transition: transform 0ms, opacity 600ms $ease-InOutCubic 250ms;
							transform: translate(0, 0);
							opacity:1;

							i, &:before, &:after{
								background-color:$color-grey-2;
							}
						}
					}
				}
			}

			.next{
				&.disable{
					.ico-next{
						&:first-child{
							display:none;
						}
						&:last-child{
							transition: transform 0ms, opacity 600ms $ease-InOutCubic 250ms;
							transform: translate(0, 0);
							opacity:1;

							i, &:before, &:after{
								background-color:$color-grey-2;
							}
						}
					}
				}
			}

			nav{
				>div{
					&:nth-last-child(2){
						top:0;
					}
				}
			}

		}

		.infos{
			height: auto;
			// height: 268px;
			ul{
				li{
					height: 100%;
					.col-1-3{
						width: 50%;
						text-align: left;
						position: absolute;
						margin-bottom: 0;
						bottom: 14px;
					}
					h2{
						margin: 15px 0 0 0;
						height: auto;
						// height: 134px;
						overflow: hidden;
						font-size: 1.8em;
					}

					&.shown{
						position:static;
						padding-bottom:35px;
					}
				}
			}
		}

		.images{

			.ratio-mobile{
				display: block;
			}

			.ratio-desktop{
				display: none;
			}

			.col-5-6{
				width: 100%;
			}

		}

		nav{
			position: relative;
			top: -45px;
			width: 100px;
			height: 30px;
			float: right;
			img{
				display: none;
			}
			>div{
				position: inherit;
				height: 40px;
				width: 40px;
				&:last-child{
					top: 0;
				}
				cursor: pointer;
			}
		}

	}

}
