#oldbrowser{
	position:fixed;
	top:0;left:0;
	width:100%;height:100%;
	background-color:$color-white;
	z-index:1000;
	text-align:center;
	display:none;
	pointer-events:all;

	&.show{
		display:block;
	}

	> div{
		position:absolute;
		top:50%;
		transform:translate(0,-50%);
		width:100%;

		a{
			svg{
				display:inline-block;
				vertical-align:middle;
				width:48px;height:48px;
				margin-right:15px;
			}
		}
	}
}
