.legals-content{
	h2{
		text-transform: uppercase;
		i{
			text-decoration: underline;
		}
	}
	a{
		color: $color-pink;
	}
	h3{
		color: $color-black;
	}
}
