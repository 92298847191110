.talent-biography{
	border-top:1px solid $color-grey-1;
	border-bottom:1px solid $color-grey-1;

	.biography-profil{
		.profil-img{
			position:relative;
			width:184px;
			overflow:hidden;
			//transform:translateZ(0);

			img{
				width:100%;
				height:auto;
			}
		}
	}
	.biography-list{
		.pink-chip{
			@include clearfix();
			padding-top:0;

			li{
				width:20%;
				height: 40px;
				float:left;
				margin-bottom:5px;
				padding-right:10px;
			}
		}
	}

	@include mobile {
		.biography-profil{
			width:40%;
			float:left;

			.profil-img{
				width:100%;
			}
		}

		.biography-list{
			width:50%;
			float:right;

			.pink-chip{
				li{
					width:100%;
					float:none;
					height: auto;
					margin-bottom:10px;
				}
			}
		}

		.biography-text{
			padding-top:30px;
			clear:both;
		}
	}
}
