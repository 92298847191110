// -------------------------o Texts


html { font-size: 100%; }

body {
	font-family: $Avenir-Medium;
	font-size: 14px;
	line-height: 22px;
}

.link-underline{
	@include underline( $color-black, 1px, 100%, 0);
	font-family: $Avenir-Black;
	font-size: $font-size-14;
}

.txt-med-3 i{
	position:relative;
	z-index:-1;

	&:before{
		content:'';
		display:block;
		position:absolute;
		bottom:6px;right:-2%;
		height:15px;
		z-index:-1;
		background-color:$color-pink;
		transform-origin:right center;

		transform: translate3d(40px, 0, 0);
		width:0;

		transition:all 1400ms $ease-InOutExpo 0ms;
	}
	&.underlined{
		&:before{
			transform: translate3d(0, 0, 0);
			width:104%;
		}
	}
}

// text styles

.txt-fat-1{
	font-family: $Avenir-Light;
	font-size: $font-size-80;
	color: $color-black;
	line-height: 1.1em;
}

.txt-fat-2{
	font-family: $Avenir-Light;
	font-size: $font-size-100;
	color: $color-black;
	line-height: 1em;
}

.txt-fat-3{
	font-family: $Avenir-Light;
	font-size: $font-size-120;
	color: $color-black;
	line-height: 1.5em;
}

.txt-fat-4{
	font-family: $Avenir-Light;
	font-size: $font-size-255;
	color: $color-pink;
	line-height: 1em;
}

.txt-med-1{
	font-family: $Avenir-Light;
	font-size: $font-size-24;
	color: $color-grey-3;
	line-height: 2em;
}

.txt-med-2{
	font-family: $Avenir-Light;
	font-size: $font-size-30;
	color: $color-black;
	line-height: 1.5em;
}

.txt-med-3{
	font-family: $Avenir-Book;
	font-size: $font-size-44;
	color: $color-black;
	line-height: 1.5em;
}

.txt-med-4{
	font-family: $Avenir-Light;
	font-size: $font-size-60;
	color: $color-black;
	line-height: 1.2em;
}

.txt-med-5{
	font-family: $Avenir-Black;
	font-size: $font-size-80;
	color: $color-white;
	line-height: 1.5em;
}

.txt-small-1{
	font-family: $Avenir-Black;
	font-size: $font-size-10;
	color: $color-black;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1em;
}
.col-1-3{
	.txt-small-1{
		padding-top:8px;
	}
}

.txt-small-2{
	font-family: $Avenir-Light;
	font-size: $font-size-12;
	color: $color-black;
}

.txt-small-3{
	font-family: $Avenir-Medium;
	font-size: $font-size-14;
	color: $color-grey-3;
	line-height: 2em;
}

// text styles shortcuts

.bold{ font-family: $Avenir-Black; }
.pink{ color: $color-pink; }
.black{ color: $color-black; }
.white{ color: $color-white; }


a{ color: $color-black; }
input::placeholder,
textarea::placeholder{ color: $color-grey-2; }
input, textarea{
	@extend .txt-med-4;
	outline: none;
}

#mce-error-response{ color: #ff5d5d; }
#mce-success-response{ color: #48ab4c; }
#mce-error-response, #mce-success-response{ display: none; margin-top: 20px; }
#mc_embed_signup{ height: 165px; }

.bg-dark{
	*{
		color: $color-white;
	}
	input::placeholder{
		color: $color-grey-4;
	}
}

// mobile style

@include mobile {
	.txt-fat-1{
		font-size: $font-size-100 / 2;
	}
	.txt-fat-2{
		font-size: 3.3em;
	}
	.txt-fat-3{
		font-size: $font-size-120 / 2;
	}
	.txt-fat-4{
		font-size: $font-size-255 / 2;
	}
	.txt-med-2{}
	.txt-med-3{
		font-size: 2.6em;
	}
	.txt-med-4{
		font-size: $font-size-44;
	}
	.txt-med-5{
		font-size: $font-size-80 / 2;
	}
	input, textarea{ font-size: 2.1em; }

	.col-1-3{
		.txt-small-1{
			padding-top:0;
		}
	}

	#mce-error-response,
	#mce-success-response{
		font-size: $font-size-12;
		line-height: 1.4em;
	}

}
