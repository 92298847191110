// -------------------------o Uils

@mixin underline( $color, $size, $position, $side) {
	background-image: linear-gradient(to bottom, $color 75%, $color 75%);
	background-repeat: repeat-x;
	background-size: 1px $size;
	background-position: 0 $position;
	padding-bottom: 2px;
	padding-left: $side;
	padding-right: $side;
}

@mixin screen($size) {
	@media #{ $size }{
		@content;
	}
}
