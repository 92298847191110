// -------------------------o Layout

.grid {
	width: $size-grid;
	margin: 0 auto;
	clear:both;
	@include clearfix();
}

.col-1-6,
.col-1-4,
.col-1-3,
.col-1-3-gut,
.col-1-2,
.col-1-2-gut,
.col-2-3 {
	float: left;
	position: relative;
	min-height: 1px;
}

.col-1-6 { width: 16.66%; }
.col-1-4 { width: 25%; }
.col-1-3 { width: 33.3%; }
.col-1-2 { width: 50%; }
.col-2-3 { width: 66.66%; }
.col-5-6 { width: 83.34%; }


.col-1-2-gut{
	&:last-child{
		margin-right: 0;
	}
	width: calc(50% - 15px);
	margin-right: 30px;
}

.col-1-3-gut{
	&:last-child{
		margin-right: 0;
	}
	width: calc(33.33% - 30px);
	margin-right: 30px;
}


.v-align{
	position: relative;
	top: 50%;
	transform: translate3d(0, -50%, 0);
}

.center{
	left: 50%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
}


.pad-1{ padding: 150px 0; }
.pad-1-top{ padding-top: 150px; }
.pad-1-bot{ padding-bottom: 150px; }

.pad-2{ padding: 100px 0; }
.pad-2-top{ padding-top: 100px; }
.pad-2-bot{ padding-bottom: 100px; }

.pad-3{ padding: 40px 0; }
.pad-3-top{ padding-top: 40px; }
.pad-3-bot{ padding-bottom: 40px; }

.pad-4{ padding: 30px 0; }
.pad-4-top{ padding-top: 30px; }
.pad-4-bot{ padding-bottom: 30px; }

.pad-5{ padding: 10px 0; }
.pad-5-top{ padding-top: 10px; }
.pad-5-bot{ padding-bottom: 10px; }

.white-background{
	background-color:$color-white;
}

// When screen width = grid

@include screen( "only screen and (max-width : " + $size-grid + ")" ) {

	.grid{
		width: 100%;
	}

}

// Tablet

@include tablet {}


// Mobile

@include mobile {

	.grid{
		width: calc(100% - 50px);
	}

	.col-1-4,
	.col-1-3,
	.col-1-2,
	.col-2-3,
	.col-1-3-gut{
		width: auto;
		float: none;
		margin: 20px 0;
	}

	.pad-1{ padding: 75px 0; }
	.pad-1-top{ padding-top: 75px; }
	.pad-1-bot{ padding-bottom: 75px; }

	.pad-2{ padding: 50px 0; }
	.pad-2-top{ padding-top: 50px; }
	.pad-2-bot{ padding-bottom: 50px; }

	.pad-3{ padding: 20px 0; }
	.pad-3-top{ padding-top: 20px; }
	.pad-3-bot{ padding-bottom: 20px; }

	.pad-4{ padding: 15px 0; }
	.pad-4-top{ padding-top: 15px; }
	.pad-4-bot{ padding-bottom: 15px; }

	.pad-5{ padding: 5px 0; }
	.pad-5-top{ padding-top: 5px; }
	.pad-5-bot{ padding-bottom: 5px; }


}
