footer{
	background-color: $color-black;
	overflow:hidden;
	.social{
		@include clearfix();
		li{
			float: left;
			margin-right: 51px;
			position: relative;
			a{
				display: block;
				padding: 10px 0 10px 25px;
			}
			i{
				position: absolute;
				top: 14px;
				left: 0;
			}
		}
	}
	.others{
		.locale{
			position: absolute;
			right: 0;
			top: 0;

			.is-active{
				font-weight:bold;
			}
		}
		a{
			i{
				font-weight:bold;
			}
		}
	}
	object{
		height:165px;
	}

	@include mobile {

		object{
			height:190px;
		}

		.social li{
			margin-right: 10px;
			a{
				width: 44px;
				color: $color-black;
				overflow: hidden;
				i{
					left: 17px;
				}
			}
		}

		.others{
			li{
				margin-bottom: 20px;
				.locale{
					position: relative;
					display: block;
					margin-top: 20px;
					a{
						margin: 0 10px 0 0;
					}
				}
			}
		}

	}
}
