.project-content{
	@include clearfix();
	// overflow:hidden;

	.col-1-3{
		margin-top:6px;
		.txt-small-1{
			font-size: $font-size-14;
		}
	}

	.video{
		img{
			opacity:0;
		}
	}

	h2.txt-med-3{
		position:relative;
		z-index:1;
	}

	.compare-slider{
		position:relative;
		overflow:hidden;
		clear:both;

		ul{
			font-size:0;
			white-space:nowrap;

			li{
				display:inline-block;
				vertical-align:middle;
				width:100%;
				overflow:hidden;

				img{
					width:100%;
				}

			}
		}

		.nav-drag{
			position:absolute;
			top:0;left:0;
			width:100%;height:100%;
			z-index:1;

			.nav-cursor-line{
				position:absolute;
				top:0;left:0;
				width:2px;height:100%;

				.line{
					position:absolute;
					top:0;
					height:100%;width:1px;

					&.black{
						left:0;
						background-color:$color-black;
					}
					&.white{
						right:0;
						background-color:$color-white;
					}
				}

				.nav-cursor{
					position:absolute;
					top:50%;right:0;
					transform:translate(50%, -50%);
					width:50px;height:50px;
					border-radius:50px;
					background:$color-black;
					cursor:pointer;

					.icons{
						position:absolute;
						left:0;top:50%;
						width:100%;
						transform:translate(0,-50%);
						font-size:0;
						line-height:1em;
						text-align:center;
						pointer-events:none;

						svg{
							display:inline-block;
							vertical-align:middle;
							width:11px;height:11px;
							margin:0 4px;
							fill:$color-white;
							pointer-events:none;
						}
					}
				}
			}
		}
	}

	@include tablet {
		.video{
			img{
				opacity:1;
				z-index:2;
			}
		}
	}
	@include mobile {
		.col-1-3, .col-2-3, .col-1-2{
			float:left;
			margin:0;
		}
		.col-1-3{
			margin-top:6px;
			width:20%;
		}
		.col-2-3{
			width:80%;
		}

		.compare-slider{
			.nav-drag{
				.nav-cursor-line{
					.nav-cursor{
						width:40px;height:40px;
						border-radius:40px;

						.icons{
							svg{
								width:8px;height:8px;
								margin:0 4px;
							}
						}
					}
				}
			}
		}

		.video{
			img{
				opacity:1;
				z-index:2;
			}
		}
	}
}
