.talent-carousel{
	&::after{
		content:'';
		display:block;
		width:100%;height:150px;
		background-color:$color-white;
	}

	@include mobile {
		&::after{
			height:50px;
		}
	}
}