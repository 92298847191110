.project-nav{
	span{
		display: block;
	}
	.grid{
		li{
			&:last-child{
				width: auto;
				max-width: 50%;
			}
		}
	}
	a{
		display: inline-block;
		width: 75%;
		p, span{
			position: relative;
			transition: color 1600ms $ease-OutExpo 0ms;
		}
		.img-container{
			position: fixed;
			z-index: 11;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			opacity: 0;
			pointer-events:none;
			background-color:$color-black;
			transform: scale(1.2);
			transition: all 1600ms $ease-OutExpo 0ms;
			.img{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity:0.2;
				transition: all 1600ms $ease-OutExpo 0ms;
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: auto;
					height: auto;
					min-width: 100%;
					min-height: 100%;
				}
			}
		}
		&.active{
			p, span{
				color: $color-white !important;
				z-index: 12 !important;
			}
			.img-container{
				opacity: 1 !important;
				transform: scale(1.1) !important;

				.img{
					opacity:0.2;
				}
			}
		}
	}

	@include mobile() {

		.grid li:first-child{
			&.pad-1 {
				padding: 75px 0 35px 0;
			}
		}

		.grid li:last-child{
			float: inherit;

			&.pad-1 {
				padding: 35px 0 75px 0;
			}
		}

	}
}
