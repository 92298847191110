.error-content{
	position:absolute;
	height:100vh;width:100%;
	top:0;
	background-color:$color-black;
	text-align:center;

	> div{
		position:absolute;
		width:100%;
		top:50%;left:0;
		transform:translate(0,-50%);

		> div{
			.back{
				position:relative;
				display:inline-block;
				vertical-align:middle;
				width:63px;height:63px;
				margin-right:15px;
				border-radius:63px;
				background-color:$color-grey-6;
				cursor:pointer;

				svg{
					position:absolute;
					top:50%;left:50%;
					margin-top:-5.5px;margin-left:-5.5px;
					width:11px;height:11px;
					fill:$color-white;
				}
			}
		}
	}

	@include mobile {
		top:auto;
		margin-top:-80px;
	}
}