// -------------------------o Colors

$color-grey-6 : #212121;
$color-grey-5 : #333333;
$color-grey-4 : #676767;
$color-grey-3 : #808080;
$color-grey-2 : #cacaca;
$color-grey-1 : #e5e5e5;
$color-grey-0 : #f7f7f7;
$color-pink : $color-grey-2;
// $color-pink : #ff3399;
$color-pink-light: #cc0066;

// -------------------------o Font sizes

$font-size-255		:	18.21em;
$font-size-120		:	8.57em;
$font-size-100		:	7.14em;
$font-size-80		:	5.71em;
$font-size-60		:	4.28em;
$font-size-44		:	3.14em;
$font-size-30		:	2.14em;
$font-size-24		:	1.71em;
$font-size-14		:	1em;
$font-size-12		:	0.85em;
$font-size-10		:	0.71em;


// -------------------------o Sizes

$size-grid: 825px;


// --------------------------o Easing

$ease-1: cubic-bezier(0.000, 0.875, 0.165, 1.000);