#turnscreen{
	position:fixed;
	top:0;left:0;
	width:100%;height:100%;
	background-color:$color-black;
	z-index:1000;

	display:none;

	.msg{
		position:absolute;
		width:100%;
		top:50%;left:0;
		transform: translate(0,-50%);
		text-align:center;

		display:none;

		svg{
			display:inline-block;
			fill:$color-white;
		}

		&.msg-portrait-tablet{
			svg{
				width:246px;height:155px;
			}
		}
		&.msg-landscape-mobile{
			svg{
				width:61px;height:124px;
			}
		}
	}

	@include tablet-portrait() {
		display:block;

		.msg-portrait-tablet{
			display:block;
		}
	}

	@include mobile-landscape() {
		display:block;

		.msg-landscape-mobile{
			display:block;
		}
	}

}