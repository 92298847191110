.home-carousel{
	.infos{
		height: 120px;
	}

	.images .parallax{
		bottom: -60%;
		top: auto;
	}

	@include mobile{
		.infos{
			height: auto;
		}
	}
}
