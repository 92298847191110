.about-clients{
	ul{
		@include clearfix();
		li{
			width: 25%;
			float: left;
			height: 130px;
			position: relative;
			i{
				position: absolute;
			}
		}
	}

	@include mobile {
		h2.pad-1{
			padding-bottom:30px;
		}

		ul{
			li{
				width: 50%;
				height: 90px;

				i{
					transform: translate3d(-50%, -50%, 0) scale(0.7);
				}
			}
		}
	}
}
